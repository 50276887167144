/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { LitElement, html } from 'lit-element';
import { connect } from 'pwa-helpers/connect-mixin';
import { store } from '../../ld-redux/ld-redux-store';
import { LdI18NextMixin } from '../../ld-application/i18n/ld-i18next';

/**
 * Class for displaying a &lt;ld-title&gt; element.
 * @extends LitElement
 */
class LdTitle extends connect(store)(LdI18NextMixin(LitElement)) {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      baseWindowTitle: { type: String },
      pageModules: { type: String },
      titleSeparator: { type: String },
      updateWindowTitle: { type: Boolean },
      _pageModule: { type: String },
      _pageTitle: { type: String }
    };
  }

  /**
   * @constructor
   */
  // --------------------------------------------------------------------------------------
  constructor() {
    super();
    this.titleSeparator = '-';
  }

  /**
   * render method, used to display the rendered template
   *
   * @returns {String} Rendered literals template
   */
  // --------------------------------------------------------------------------------------
  render() {
    return html`
      ${this._pageTitle}
    `;
  }

  /**
   * @returns {String} Current selected module name
   */
  // --------------------------------------------------------------------------------------
  _getCurrentPageModuleTitle() {
    const activeModule = this._pageModule;

    const activeItem = JSON.parse(this.pageModules).find(pageModule => pageModule.id === activeModule);

    if (this.updateWindowTitle) {
      let windowTitle = '';

      if (this.baseWindowTitle && typeof this.baseWindowTitle !== 'undefined') {
        windowTitle = `${this.baseWindowTitle} ${this.titleSeparator} `;
      }
      windowTitle += this.LdTranslate(activeItem.name);
      if (document.title) {
        document.title = windowTitle;
      }
    }

    return this.LdTranslate(activeItem.name);
  }

  /**
   * @param {JSON} - updated state handled by the redux implementation
   */
  // --------------------------------------------------------------------------------------
  stateChanged(state) {
    this._pageModule = state.LdApplicationReducer.pageModule;
    if (this._pageModule != null) {
      this._pageTitle = this._getCurrentPageModuleTitle();
    }
  }

}

customElements.define('ld-title', LdTitle);
