/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

'use strict';

import { css } from 'lit-element';

export const LdPrivacyPolicyStyles = css`
  #privacyPolicyDialog {
    z-index: 99999;
    width:40%;
  }
  
  paper-dialog#privacyPolicyDialog {
    top: 16px;
    overflow: auto;
  }
  
  paper-dialog#privacyPolicyDialog .privacy-language {
    margin:0 0 0 20px;
    padding: 15px 5px 0 5px;
  }
  
  paper-dialog#privacyPolicyDialog .privacy-language a {
    padding: 0 5px;
  }
  
  paper-dialog#privacyPolicyDialog .privacy-language a:first-child {
    padding-left:0;
  }
  
  paper-button#privacyPolicyDialogButtonClose {
  }
`;

export default LdPrivacyPolicyStyles;
