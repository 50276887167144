import { unsafeCSS, css } from 'lit-element';

/**
 * Style module for ld-loading-spinner component.
 * @module styles/LdLanguageSelectorStyle
 */

/**
 * Style template for ld-loading-spinner component.
 */
const LdLoadingSpinnerStyles = css`
  .ld-loading-spinner-pop-out paper-dialog{
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--platform-frame-loading-spinner-background-color, var(--sw-primary-loading-background-color, #808080));
    opacity: 0.5;
    z-index: 1003; /* Specify a stack order in case you're using a different order for other elements */
  }
  .ld-loading-spinner {
    position: absolute;
    height: 100px;
    width: 100px;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    z-index: 1004;
    
    --paper-spinner-layer-1-color: #FFFFFF;
    --paper-spinner-layer-2-color: #eb0028;
    --paper-spinner-layer-3-color: #FFFFFF;
    --paper-spinner-layer-4-color: #eb0028;
    --paper-spinner-stroke-width: 10px;
    
  }

  @keyframes fadeIt {
    0%   { background-color: #FFFFFF; }
    25%  { background-color: #eb0028; }
    50% { background-color: #FFFFFF; }
    75% { background-color: #eb0028; }
    100% { background-color: #FFFFFF; }
  }
`;

export default LdLoadingSpinnerStyles;
