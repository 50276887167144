/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { CONFIG } from '../LdGlobal';
import LdFetch from '../LdFetch';

const { base_url, endpoints } = CONFIG.backend.services.assets;
const url = `${base_url}${endpoints.companies}`;

function loadLines(companyId) {
  return new Promise((resolve, reject) => {
    LdFetch.get(`${url}/${companyId}`, resolve, reject);
  });
}

export default {
  loadLines,
};
