/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

'use strict';

export const themeFonts = {
  main_font: 'Calibri, Roboto, Helvetica, sans-serif;',
  main_font_size: '13px',
};

export default themeFonts;
