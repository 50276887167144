/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { CONFIG } from '../LdGlobal';
import LdFetch from '../LdFetch';

const { base_url, search_all } = CONFIG.backend.services.search;
const url = `${base_url}${search_all}`;

function searchAll(searchInput) {
  const abortController = new AbortController()
  const signal = abortController.signal;
  const requestPromise = new Promise((resolve, reject) => {
    LdFetch.get(`${url}${searchInput}`, resolve, reject, { signal });
  });
  requestPromise.abort = () => abortController.abort();
  return requestPromise;
}

export default {
  searchAll,
};
