/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

'use strict';

/* Import the active theme */
import { themeColors, themeFonts } from './lifedata';

export const _THEME = {
  colors: themeColors,
  fonts: themeFonts
};

export default _THEME;
