
import { css } from "lit";

export const styles = css`
:host {
    display: flex;
    width: 100%;
    height: 100%;
}
.wrapper {
    display: flex;
    flex-direction: column;
    flex: auto;
    background-color: #fff;
    color: #000;
    padding: 5px 0;
    z-index: 1;
    font-size: 13px;
    -webkit-animation: fadein-password-rules-view 1s;
    -moz-animation: fadein-password-rules-view 1s;
    -ms-animation: fadein-password-rules-view 1s;
    -o-animation: fadein-password-rules-view 1s;
    animation: fadein-password-rules-view 1s;
}

/* This is the pointer */
.wrapper.pointer-left::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent white transparent transparent;
}

/* This is the pointer */
.wrapper.pointer-right::after {
    content: " ";
    transform: rotate(180deg);
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent white transparent transparent;
}

.wrapper ul {
    margin: 0;
    padding: 0 0 0 10px;
    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
}

.wrapper li {
    list-style: none;
}

*[data-pass='true'] {
    color: forestgreen;
}

@keyframes fadein-password-rules-view {
    from {
    opacity: 0;
    }
    to {
    opacity: 1;
    }
}
@-webkit-keyframes fadein-password-rules-view {
    from {
    opacity: 0;
    }
    to {
    opacity: 1;
    }
}
@-moz-keyframes fadein-password-rules-view {
    from {
    opacity: 0;
    }
    to {
    opacity: 1;
    }
}
@-ms-keyframes fadein-password-rules-view {
    from {
    opacity: 0;
    }
    to {
    opacity: 1;
    }
}
@-o-keyframes fadein-password-rules-view {
    from {
    opacity: 0;
    }
    to {
    opacity: 1;
    }
}

/* utility */
.rounded {border-radius: 0.25rem; /* 4px */}
.text-sm {
    font-size: 0.875rem; 
    line-height: 1.25rem; 
}
.text-base {
    font-size: 1rem; 
    line-height: 1.5rem;
}
`;
