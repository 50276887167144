/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { LitElement, html, css } from 'lit-element';
import '@polymer/iron-icon/iron-icon';
import '@polymer/paper-item/paper-item';
import '@polymer/paper-item/paper-icon-item';
import '@polymer/paper-item/paper-item-body';
import '@polymer/paper-icon-button/paper-icon-button';

import { LdSearchStyles } from '../styles';
import { LdI18NextMixin } from '../../../ld-application/i18n/ld-i18next';

/**
 * Class for displaying a &lt;ld-sidebar-item&gt; element.
 * @extends LitElement
 */
class LdSearchResultGenericTemplate extends LdI18NextMixin(LitElement) {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      link: { type: String },
      image: { type: String },
      firstLine: { type: String },
      secondLine: { type: String },
      data: { type: Object }
    };
  }

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdSearchStyles, css`
      :host {
        display: block;
  
        --paper-item-body-two-line-min-height: 60px;
        --paper-item-icon-width: 5px;
      }
    `];
  }

  /**
   * render method, used to display the rendered template
   *
   * @returns {String} Rendered literals template
   */
  // --------------------------------------------------------------------------------------
  render() {
    return html`
      <a class='paper-item-link' href='${this.link}' tabindex='-1' @click="${this._click}">
        <paper-icon-item>
          <iron-icon src='${this.image}' item-icon></iron-icon>
          <paper-item-body two-line>
            <div>${this.firstLine}</div>
            <div secondary>${this.secondLine}</div>
          </paper-item-body>
        </paper-icon-item>
      </a>
    `;
  }

  _click(event) {
    this.dispatchEvent(new CustomEvent('result-selected', {
      detail: {
        data: this.data,
        preventNavigation: () => event.preventDefault(),
      },
      composed: true,
    }));
  }

}

customElements.define('ld-search-generic-result-template', LdSearchResultGenericTemplate);
