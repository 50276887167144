/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { LitElement, html } from 'lit-element';

import { LdWaitingStyles } from './styles';

class LdWaiting extends LitElement {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
    };
  }

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdWaitingStyles];
  }

  // --------------------------------------------------------------------------------------
  render() {
    return html`
      <div id="ld-waiting">Please wait while the SW CloudPlatform is loading</div>
    `;
  }

}

customElements.define('ld-waiting', LdWaiting);
