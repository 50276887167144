/**
 * Copyright 2018 Schwäbische Werkzeugmaschinen GmbH
 */

import { css, html, LitElement, property, unsafeCSS } from 'lit-element';
import { TemplateResult }                             from 'lit-html';
import _THEME                                         from '../../../themes/styles';
import { LdI18NextMixin, LdTranslate } from '../../ld-application/i18n/ld-i18next';


/**
 * @extends LitElement
 */
export class LdProgressBar extends (LitElement) {

  // --------------------------------------------------------------------------------------
  @property({ type: String }) max: number                                      = 0;
  @property({ type: String }) current: number                                  = 0;
  @property({ type: String }) suffixHeader: string                                  = '';


  // ---------------------------------------------------------------------------------------
  static get styles() {
    return [css`
      :host {
        --progress-color: ${unsafeCSS(_THEME.colors.primary_color)};
        --progress-background: #e3e3e3;
      }
      .progress-text {
        font-size: 0.8rem;
      }
      progress[value] {
        appearance: none;
        border: none;
        width: 100%;
        height: 20px;
        border-radius: 3px;
      }
      progress[value]::-webkit-progress-bar {
        background-color: var(--progress-background);
        border-radius: 8px;
        padding: .3rem;
      }
      progress[value]::-moz-progress-bar {
        background: var(--progress-color);
        border-radius: 8px;
      }
      progress::-webkit-progress-value {
        background: var(--progress-color);
        border-radius: 8px;
      }
    `];
  }

  // --------------------------------------------------------------------------------------
  render() {
    return html`
      <p>${LdTranslate(this.suffixHeader)}</p>
      <progress id="progress" max="100" value='${this.current*(100/this.max)}'></progress>
      <p>${this.current} ${LdTranslate('modules.generic.of')} ${this.max} ${LdTranslate('modules.generic.downloaded')}</p>
    `;
  }
}

customElements.define('ld-progress-bar', LdProgressBar as unknown as CustomElementConstructor);
