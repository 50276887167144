/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

'use strict';

import { unsafeCSS, css } from 'lit';
import { _THEME } from '../../../themes/styles';

export const LdFormStyles = css`
  :host {
    display: block;
  }

  /**
   * -----------------------------------------------------------------------------------------------------------
   * FORM STYLING
   * -----------------------------------------------------------------------------------------------------------
   **/

  .outer-container {
    background-color: var(--platform-form-empty-container, ${unsafeCSS(_THEME.colors.primary_background_color)});
    padding: 25px;
  }
  .inner-container {
      margin:0 auto;
      width:28em;
    }

  paper-toggle-button.red {
    --paper-toggle-button-checked-bar-color:  ${unsafeCSS(_THEME.colors.primary_color)};
    --paper-toggle-button-checked-button-color:  ${unsafeCSS(_THEME.colors.primary_color)};
    --paper-toggle-button-checked-ink-color: ${unsafeCSS(_THEME.colors.primary_color)};
  }
  
  @media only screen and (min-width: 600px ) {
    .inner-container {
      margin:0 auto;
      width:28em;
    }
  }
  @media only screen and (min-width: 1260px ) {
    .inner-container {
      margin:0 auto;
      width:50em;
    }
  }

  .align-center {
    text-align:center;
  }

  paper-dropdown-menu {
    width: 100%;
  }

  paper-listbox {
    width: 100%;
  }

  paper-input, paper-dropdown-menu {
    margin-bottom: 10px;
  }

  iron-icon {
    color: ${unsafeCSS(_THEME.colors.secondary_text_color)};
  }

  .dropdown-content {
    color: var(--platform-frame-dropdown-content-color, ${unsafeCSS(_THEME.colors.secondary_text_color)});
    min-width: 400px !important;
  }
  
  paper-item:hover {
    background-color: ${unsafeCSS(_THEME.colors.primary_highlight_color)};
    cursor: pointer;
  }
 
    ld-datepicker-config {
     width: 100%;
    padding: 25px 0px 25px 0px;
    color: black;
  }

`;

export default LdFormStyles;
