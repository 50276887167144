import { forward, goToUrl } from '../LdGlobal';

type RouteChangeConfig = {
  useSpinner: boolean;
};

export interface Router {
  /**
   * Changes URL without reload of page.
   * @param path
   * @param options
   */
  changeView(path: string, options?: RouteChangeConfig): void;

  /**
   * Changes URL with reload of page.
   * @param path
   */
  changeLocation(path: string): void;
}

export class BasicRouter implements Router {

  changeView(path: string, options: RouteChangeConfig = { useSpinner: true }): void {
    if (options.useSpinner) {
      forward(path);
    } else {
      goToUrl.assign(path);
    }
  }

  changeLocation(path: string): void {
    window.location.assign(path);
  }
}
