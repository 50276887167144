/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { css, unsafeCSS } from 'lit-element';
import { displayFlex, endJustified, horizontal, startJustified } from 'lit-flexbox-literals';
import { _THEME } from '../../../../themes/styles';

export const LdSettingsViewStyles = css`

  ld-datepicker-config {
    --ld-datepicker-show-component-border-bottom: none;
  }
  
  .ld-variables-content-wrapper {
    ${displayFlex}
  }

  .ld-variables-list-view {
    width: 450px;
    min-height: 155px;
    margin-right: 5px;
    ${horizontal}
  }

  .ld-variables-content-view {
    ${horizontal}
    width: 100%;
  }

  .ld-variables-content-view .no-selected-content {
    text-align: center;
    background-color: #FFFFFF;
    padding: 16px;
  }

  .ld-variables-content-view .no-selected-content p {
    color: #000;
  }

  .ld-variables-content-view .ld-variables-content-chart-container {
    background-color: #FFFFFF;
    text-align: center;
    height: 460px;
    margin-bottom: 5px;
    color: #000;
  }

  ld-buttonlist {
    width: 25%;
  }

  ld-button {
    display: block;
    margin-top: 5px;
  }

  ::part(ld-part-button) {
    display: block;
    margin: 12px 0px;
    background-color: ${unsafeCSS(_THEME.colors.button_background_color)};
    color: ${unsafeCSS(_THEME.colors.primary_color)};
    font-weight: bold;
    text-align: center;
  }
  
  .ld-collections-content-wrapper {
    ${displayFlex}
  }

  .ld-collections-list-view {
    width: 450px;
    max-height: 500px;
    margin-right: 5px;
    ${horizontal}
  }

  .ld-collections-content-view {
    ${horizontal}
    width: 100%;
  }

  .ld-collections-content-view .no-selected-content {
    text-align: center;
    background-color: #FFFFFF;
    padding: 16px;
  }

  .ld-collections-content-view .no-selected-content p {
    color: #000;
  }

  .ld-collections-content-view .ld-collections-content-chart-container {
    background-color: #FFFFFF;
    text-align: center;
    height: 460px;
    margin-bottom: 5px;
    color: #000;
  }
  
  .ld-maintenance-content-wrapper {
    ${displayFlex}
  }

  .ld-maintenance-list-view {
    width: 350px;
    max-height: 500px;
    margin-right: 5px;
    ${horizontal}
  }

  .ld-maintenance-content-view {
    ${horizontal}
    width: 100%;
  }

  .ld-maintenance-content-view .no-selected-content {
    text-align: center;
    background-color: #FFFFFF;
    padding: 16px;
  }

  .ld-maintenance-content-view .no-selected-content p {
    color: #000;
  }

  .ld-maintenance-content-view .ld-maintenance-content-chart-container {
    background-color: #FFFFFF;
    text-align: center;
    height: 400px;
    margin-bottom: 5px;
    color: #000;
  }

  .ld-limit-list-wrapper .headline {
    background-color: #fff;
    height: auto;
    padding: 2px 5px;
    ${displayFlex}
    ${horizontal}
    ${startJustified}
    margin-bottom: 5px;
    margin-right: 0px;
  }

  .ld-limit-list-wrapper .headline > * {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }

  .ld-limit-list-wrapper .headline h3 {
    text-transform: uppercase;
    font-size: 18px;
    margin: 10px 15px;
    color: ${unsafeCSS(_THEME.colors.primary_header_color)};
  }

  .ld-limit-list-wrapper .headline h3::before {
    content: "/ ";
    font-weight: 800;
    color: ${unsafeCSS(_THEME.colors.primary_highlight_color)};
  }

  .ld-limit-list-wrapper .headline ld-datepicker-config {
    display: block;
    margin-left: auto;
    padding-top: 8px;
    width: 600px !important;
    max-width: 600px !important;
    ${endJustified}
  }

  .ld-limit-list-wrapper .headline .heading-icons {
    ${endJustified}
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 20px;
    padding-top: 6px;
    border-left: 1px ${unsafeCSS(_THEME.colors.icons_color)} solid;
  }

  .description .head {
    height: auto;
    padding: 2px 5px;
    ${displayFlex}
    ${horizontal}
    ${startJustified}
    margin-bottom: 5px;
    margin-right: 0px;
  }

  .description .head > * {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }

  #uploadForImport {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 20px;
  }

  #uploadForImport:before {
    content: '1';
  }
  
  #selectMachinesForImport {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 20px;
  }
  #selectMachinesForImport:before {
    content: '2';
  }
  
  #selectMachinesForImport:before, #uploadForImport:before {
    display: inline-block;
    width: 20px;
    height: 27px;
    margin-right: 10px;
    padding-left: 9px;
    padding-top: 2px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    color: ${unsafeCSS(_THEME.colors.primary_text_color)};
    background-color:${unsafeCSS(_THEME.colors.primary_color)};
  }

    .description .head h3 {
      text-transform: uppercase;
      font-size: 18px;
      margin: 10px 15px;
      color: ${unsafeCSS(_THEME.colors.primary_text_color)};
    }

    .actions .head {
      height: auto;
      padding: 2px 5px;
      ${displayFlex}
      ${horizontal}
      ${startJustified}
      margin-bottom: 5px;
      margin-right: 0px;
    }

    .actions .head > * {
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
    }
  
  .space{
    margin-top: 60px;
  }

    .actions .head h3 {
      text-transform: uppercase;
      font-size: 18px;
      margin: 10px 15px;
      color: ${unsafeCSS(_THEME.colors.primary_header_color)};
    }

    .ld-limit-wrapper {
      color: ${unsafeCSS(_THEME.colors.secondary_text_color)};
      background-color: ${unsafeCSS(_THEME.colors.primary_background_color)};
      margin-bottom: 5px;
      padding: 25px;
      display: flex;
      margin-right: 0px;
    }

    .description {
      flex: 0 1 auto;
      width: 45%;
      margin-right: 10px;
      background-color: ${unsafeCSS(_THEME.colors.app_drawer_background_color)};
      color: ${unsafeCSS(_THEME.colors.primary_text_color)};
    }

    .description p {
      height: auto;
      padding: 2px 5px;
      ${displayFlex}
      ${horizontal}
      ${startJustified}
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      font-size: 16px;
      margin: 10px 15px;
      color: ${unsafeCSS(_THEME.colors.primary_text_color)};
    }

    .actions {
      flex-direction: row;
      width: 60%;
    }

    .actions p {
      height: auto;
      padding: 2px 5px;
      ${displayFlex}
      ${horizontal}
      ${startJustified}
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      font-size: 16px;
      margin: 10px 15px;
    }

    paper-button {
      right: 30px;
      float: right;
      display: block;
      margin-top: 5px;
      width: 25%;
      display: block;
      margin: 12px 0px;
      background-color: ${unsafeCSS(_THEME.colors.button_background_color)};
      color: ${unsafeCSS(_THEME.colors.primary_color)};
      font-weight: bold;
      text-align: center;
    }

    .report {
      overflow-y: scroll;
      height: 45%;
    }

    @media only screen and (min-width: 600px ) {
      .disabledOnPad {
        display: none;
      }
    }
    @media only screen and (min-width: 1260px ) {
      .disabledOnPad {
        display: inline;
      }
    }
`;

export default LdSettingsViewStyles;
