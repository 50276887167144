/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

'use strict';

export const themeColors = {
  primary_color: '#E4002B',
  primary_background_color: '#FFFFFF',
  primary_text_color: '#FFFFFF',
  primary_highlight_color: '#E4002B',
  primary_header_background_color: '#FFFFFF',
  primary_header_color: '#000000',
  primary_loading_background_color: '#808080',
  primary_loading_spinner_color: '#FFFFFF',
  secondary_color: '#263448',
  secondary_background_color: '#737272',
  secondary_text_color: '#000000',
  secondary_note_color: '#949494',
  secondary_highlight_color: 'rgba(231, 47, 54, 0.2);',
  default_background_color: '#000F26',
  default_borders_color: '#CECECE',
  default_accent_color: '#666666',
  button_background_color: '#FAFAFA',
  button_secondBackground_color: '#206FB5',
  button_thirdBackground_color: '#E4002B',
  button_fourthBackground_color: '#001524',
  button_fifthBackground_color: '#8ab230',
  button_sixthBackground_color:'#8AB230',
  button_seventhBackground_color:'#821E29',
  main_text_color: '#000000',
  fab_background: '#E4002B',
  fab_secondary_background: 'grey',
  icon_button_color: '#727272',
  paper_item_pressed: '#D5D5D5',
  paper_item_active_border_color: '#E72F36',
  app_header_color: 'rgba(51, 51, 51, 1)',
  app_header_background_color: 'rgba(255, 255, 255, 1)',
  app_drawer_background_color: 'rgba(36, 58, 79, 1)',
  app_drawer_scrim_background_color: 'rgba(36, 58, 79, 1)',
  app_drawer_sidebar_background_color: '#677583',
  datepicker_border_color: '#D3D3D3',
  datepicker_color: '#808080',
  search_field_background_color: '#F1F1F1',
  search_field_text_color: '#607D8B',
  search_field_item_background_color: '#E4E4E4',
  grid_background_color: '#F1F1F1',
  grid_border_color: '#E6E6E6',
  icons_color: '#98A3AD',
  icons_hover_background_color: '#F2F2F2',
  icons_active_color: '#333333',
  selected_burger_menu: '#e6e6e6',
  selected_menu_item: '#335CAD',
  breadcrumb_main_color: '#98a3ad',
  secondary_green_100: '#DAD6BF',
  secondary_green_200: '#BBB487',
  secondary_green_300: '#3f8000',
  secondary_green_400: '#7E761A',
  secondary_green_500: '#676223',
  secondary_green_600: '#4D4B1A',
  trafficlight_green: '#8ab230',
  trafficlight_red: '#E4002B',
  trafficlight_gray: '#737071',
  trafficlight_yellow: '#ffe400',
  availability_chart_colors: {
    auto: '#8ab230',
    auto_waiting_for_parts: '#CFB968',
    auto_waiting_for_parts_stopping_error: '#E4002B',
    auto_with_error_program_stopped: '#821E29',
    auto_no_program_active: '#ffe400',
    jog_with_error: '#9B504C',
    jog_without_error: '#ea8a00',
    mda_with_error: '#B7867D',
    mda_without_error: '#b7720e',
    not_defined_condition: '#737071'
  },
  undefined: '#737071',
  tool_change_time: '#D88736',
  machining_time:'#7C933D',
  auxiliary_process_time:'#EEBA3D',
  limit_colors: {
    critical: '#E4002B',
    prewarning: '#ffe400',
  },
  axistest: {
    limit_area_first_series: '#5757ED22',
    limit_area_second_series: '#34893422',
    first_series: '#5757ED',
    second_series: '#348934',
    circularity_custom_y_axis: '#E6E6E6',
    warning_limit: '#FFA500',
    critical_limit: '#FF0000',
    kpi_table_border: '#E6E6E6',
    kpi_table_background_accent: '#F3F3F3',
  }
};

export default themeColors;
