/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { css, html, LitElement } from 'lit-element';
import '@polymer/iron-icon/iron-icon';
import '@polymer/paper-tooltip/paper-tooltip';
import { LdI18NextMixin } from '../../ld-application/i18n/ld-i18next';

import './result-templates/ld-search-result-machine';

/**
 * Class for displaying a &lt;ld-sidebar-item&gt; element.
 * @extends LitElement
 */
class LdSearchResultTemplateSelector extends LdI18NextMixin(LitElement) {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      data: { type: Object, notify: true },
    };
  }

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [css`
      :host {
        display: block;
      }
    `];
  }

  /**
   * render method, used to display the rendered template
   *
   * @returns {String} Rendered literals template
   */
  // --------------------------------------------------------------------------------------
  render() {

    return html`
      <ld-search-result-machine .data='${this.data}'>
        <ld-search-result-machine>
    `;
  }

}

customElements.define('ld-search-result-template-selector', LdSearchResultTemplateSelector);
