/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { html, LitElement } from 'lit-element';
import './ld-search-generic-result-template';
import { getUUID } from '../../../../js/LdGlobal';

class LdSearchResultMachine extends LitElement {

  static get properties() {
    return {
      data: { type: Object, notify: true },
    };
  }

  constructor() {
    super();
    this.data = {};
  }

  render() {
    if (this.data.uri != null) {
      return html`
        <ld-search-generic-result-template
          link='/assets/machine/view/${getUUID(this.data)}/performance'
          image='/images/dashboard/machine.svg'
          firstLine='${this.data.machine_identifier_customer}'
          secondLine='${this.data.name} ${this.data.machine_type}'
          .data='${this.data}'
        ></ld-search-generic-result-template>
      `;
    }
  }

}

customElements.define('ld-search-result-machine', LdSearchResultMachine);
