/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { LitElement, html } from 'lit-element';
import '@polymer/iron-icon/iron-icon';

import { LdSidebarItemStyles } from './ld-sidebar-item-styles';


/**
 * Class for displaying a &lt;ld-sidebar-item&gt; element.
 * @extends LitElement
 */
class LdSidebarItem extends LitElement {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      item: Object
    };
  }

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdSidebarItemStyles];
  }

  /**
   * render method, used to display the rendered template
   *
   * @returns {String} Rendered literals template
   */
  // --------------------------------------------------------------------------------------
  render() {
    const _item = JSON.parse(this.item);
    return html`
      <iron-icon src="/images/sidebar/icon_${_item.icon}.png" class="sw-iron-icon" item-icon slot="item-icon"></iron-icon>
    `;
  }

}

customElements.define('ld-sidebar-item', LdSidebarItem);
