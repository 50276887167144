/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */
/* eslint-disable import/no-cycle */

import {
  createStore,
  applyMiddleware,
  compose as origCompose,
  combineReducers
} from 'redux';
import thunk from 'redux-thunk';
import { lazyReducerEnhancer } from 'pwa-helpers/lazy-reducer-enhancer';
import { loadState, saveState } from './ld-localstorage';

import LdApplicationReducer from './reducers/ld-application-reducer';
import LdAssetsReducer from './reducers/ld-assets-reducer';
import LdTimeseriesReducer from './reducers/ld-timeseries-reducer';
import LdDashboardReducer from './reducers/ld-dashboard-reducer';
import LdUserManagementReducer from './reducers/ld-user-management-reducer';
import LdUsersReducer from './reducers/ld-users-reducer';
import BreadCrumbReducer from './reducers/breadcrumb-reducers';

const reducers = {
  LdApplicationReducer,
  LdAssetsReducer,
  LdTimeseriesReducer,
  LdUserManagementReducer,
  LdDashboardReducer,
  LdUsersReducer,
  BreadCrumbReducer
};

window.SWCP = {};
window.SWCP.__loaded = false;
window.SWCP.__shouldInvalidate = false;
const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ serialize: true }) : origCompose;
const savedState = loadState();

const purgedState = Object.keys(savedState)
  .filter(key => key in reducers)
  .reduce((obj, key) => {
    obj[key] = savedState[key];
    return obj;
  }, {});

export const store = createStore(
(state, action) => state,
  purgedState,
  compose(lazyReducerEnhancer(combineReducers), applyMiddleware(thunk))

);

export default store;

store.addReducers(reducers);

store.subscribe(() => {

  saveState(store.getState());
});
