/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { LitElement, html } from 'lit-element';
import { connect } from 'pwa-helpers/connect-mixin';
import { store } from '../../ld-redux/ld-redux-store';

import { LdI18NextMixin } from '../i18n/ld-i18next';

import '@polymer/paper-tooltip/paper-tooltip';
import '@polymer/paper-dialog/paper-dialog';
import '@polymer/paper-dialog-scrollable/paper-dialog-scrollable';
import '@lifedata/ld-markdown-element/ld-markdown-element.js'

import { LdPrivacyPolicyStyles } from './styles';

class LdPrivacyPolicy extends connect(store)(LdI18NextMixin(LitElement)) {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      opened: { type: Boolean, hasChanged: () => true },
      acceptDecline: { type: Boolean, hasChanged: () => true },
      _privacyDocumentLanguage: { type: String, hasChanged: () => true }
    };
  }

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdPrivacyPolicyStyles];
  }

  // --------------------------------------------------------------------------------------
  constructor() {
    super();
    this.addEventListener('language-changed', this._updateLanguage());

    this.opened = false;
    this.acceptDecline = true;
    this._updateLanguage();
  }

  // --------------------------------------------------------------------------------------
  render() {
    const _buttons = [];
    const _content = [];
    if (this.acceptDecline === true) {
      _buttons.push(html`
        <div class='buttons'>
          <paper-button id='privacyPolicyDialogButtonDecline' @click='${this._decline}'>${this.LdTranslate('generic.declineLabel')}</paper-button>
          <paper-button id='privacyPolicyDialogButtonAccept' @click='${this._accept}'>${this.LdTranslate('generic.acceptLabel')}</paper-button>
        </div>
      `);
    } else {
      _buttons.push(html`
        <div class='buttons'>
          <paper-button id='privacyPolicyDialogButtonClose' @click='${this.close}'>${this.LdTranslate('generic.closeLabel')}</paper-button>
        </div>
      `);
    }

    if (this._privacyDocumentLanguage != null) {
      _content.push(html` 
        <paper-dialog-scrollable id='scrollabledialog'>
          <ld-markdown-element>
            <div slot="markdown-html"></div>
            <script
              slot="markdown-src"
              type="text/markdown"
              src='/static/PRIVACY_POLICY_${this._privacyDocumentLanguage.toUpperCase()}.md'
            ></script>
          </ld-markdown-element>
        </paper-dialog-scrollable>
      `);
    }
    return html`
      <paper-dialog id='privacyPolicyDialog' no-cancel-on-esc-key='true' modal no-cancel-on-outside-click='true' ?opened="${this.opened}">
        <h2>${this.LdTranslate('generic.footerLinks.privacy.header')}</h2>
        <div class='privacy-language'>
          <a @click='${() => this.setLanguage('EN')}'  href='javascript:;'>ENGLISH VERSION</a> | <a @click='${() => this.setLanguage('DE')}' href='javascript:;'>DEUTSCHE VERSION</a>
        </div>
        ${_content}
        ${_buttons}
      </paper-dialog>
    `;
  }

  // --------------------------------------------------------------------------------------
  open() {
    if (this._isElementAvailable()) {
      this.shadowRoot.querySelector('paper-dialog#privacyPolicyDialog')
        .open();
    }
  }

  // --------------------------------------------------------------------------------------
  close() {
    this.shadowRoot.querySelector('paper-dialog#privacyPolicyDialog').close();
  }

  // --------------------------------------------------------------------------------------
  disableAcceptDeclineButton() {
    this.acceptDecline = false;
  }

  // --------------------------------------------------------------------------------------
  setLanguage(lang) {
    this._changePrivacyDocumentLanguage(lang);
  }

  // --------------------------------------------------------------------------------------
  stateChanged(state) {
    const { userHasAcceptedPrivacyPolicyStatus } = state.LdUsersReducer;

    if (userHasAcceptedPrivacyPolicyStatus != null) {
      // @todo: Check if this value is always returned when no consent was given
      if (userHasAcceptedPrivacyPolicyStatus.status === 'NEED_ACCEPTANCE') {
        setTimeout(() => {
          this.open();
        }, 500)
      }
    }
  }

  // --------------------------------------------------------------------------------------
  _updateLanguage() {
    this._privacyDocumentLanguage = this.LdGetLanguage();
  }

  // --------------------------------------------------------------------------------------	
  _changePrivacyDocumentLanguage(lang) {
    this._privacyDocumentLanguage = lang;
  }

  // --------------------------------------------------------------------------------------
  _accept() {
    this.disableAcceptDeclineButton();
    this.dispatchEvent(new CustomEvent('policy-status-changed', { detail: { accepted: true } }));
  }

  // --------------------------------------------------------------------------------------
  _decline() {
    this.dispatchEvent(new CustomEvent('policy-status-changed', { detail: { accepted: false } }));
  }

  // --------------------------------------------------------------------------------------
  _isElementAvailable() {
    return this.shadowRoot.querySelector('paper-dialog#privacyPolicyDialog') != null;
  }

}

customElements.define('ld-privacy-policy', LdPrivacyPolicy);
