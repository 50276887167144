/**
 * Copyright 2023 Schwäbische Werkzeugmaschinen GmbH
 */
import { IFBreadCrumbConfigObject } from '../../../js/interfaces';
import LdFetch                      from '../../../js/LdFetch';
import { CONFIG }                   from '../../../js/LdGlobal';

export const START_LOADING_BREADCRUMB_CONFIG  = 'START_LOADING_BREADCRUMB_CONFIG';
export const FINISH_LOADING_BREADCRUMB_CONFIG = 'FINISH_LOADING_BREADCRUMB_CONFIG';
export const ERROR_LOADING_BREADCRUMB_CONFIG  = 'ERROR_LOADING_BREADCRUMB_CONFIG';
export const BREADCRUMB_CONFIG = 'BREADCRUMB_CONFIG';
export const CLEAR_SUBMIT_BREADCRUMB = 'CLEAR_SUBMIT_BREADCRUMB';
// --------------------------------------------------------------------------------------
const receiveSetBreadCrumbConfig = (breadcrumbList: object) => ({
  type: FINISH_LOADING_BREADCRUMB_CONFIG,
  breadcrumbList,
});

// --------------------------------------------------------------------------------------
const errorSetBreadCrumbConfig = (error: object) => ({
  type: ERROR_LOADING_BREADCRUMB_CONFIG,
  error,
});

// --------------------------------------------------------------------------------------
export const getBreadCrumbConfig = (assetType: string, assetID: string) => (dispatch: any) => {
  dispatch({ type: START_LOADING_BREADCRUMB_CONFIG });
  return LdFetch.get(`${CONFIG.backend.services.assets.base_url}${CONFIG.backend.services.assets.endpoints.breadcrumb}/${assetType}/${assetID}`, (breadcrumbList: object) => {
    dispatch(receiveSetBreadCrumbConfig(breadcrumbList));
  }, (error: object) => {
    dispatch(errorSetBreadCrumbConfig(error));
  });
};

export const setBreadCrumbConfig = (breadCrumbConfig: IFBreadCrumbConfigObject) => (dispatch: any) => {
  dispatch({ type: BREADCRUMB_CONFIG, breadCrumbConfig });
};


// --------------------------------------------------------------------------------------
export const clearBreadCrumbConfig = () => (dispatch: any) => {
  dispatch({ type: CLEAR_SUBMIT_BREADCRUMB });
};
