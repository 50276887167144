/**
 * Copyright 2023 Schwäbische Werkzeugmaschinen GmbH
 */


import { unsafeCSS, css } from 'lit-element';
import { _THEME } from '../../../themes/styles';

export const LdBreadcrumbsStyles = css`
  /* Style the list */
  ul.breadcrumb {
    list-style: none;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 55px;
    padding-inline-start: 0px;
    display: inline-flex;
  }
  /* Display list items side by side */
  ul.breadcrumb li {
    font-size: 18px;
    color: ${unsafeCSS(_THEME.colors.primary_text_color)}
    cursor: pointer;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;

  }
  /* Add a slash symbol (/) before/behind each list item */
  ul.breadcrumb li+li:before {
    padding: 8px;
    color: ${unsafeCSS(_THEME.colors.primary_highlight_color)};
    content: "/\\00a0";
  }
  /* Add a color to all links inside the list */
  ul.breadcrumb li a {
    color: ${unsafeCSS(_THEME.colors.breadcrumb_main_color)};
    text-decoration: none;
    cursor: pointer;
  }

  ul.breadcrumb li:last-child a {
    color: ${unsafeCSS(_THEME.colors.primary_text_color)};
    font-weight: bold;
  }
  /* Add a color on mouse-over */
  ul.breadcrumb li a:hover {
    color: ${unsafeCSS(_THEME.colors.primary_highlight_color)};
  }
  
`;
