/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

const initalState = {
  trackedMachines: new Set(),
};

const filter = (mySet, elementToRemove) => {
  const newSet = new Set(mySet);
  newSet.delete(elementToRemove);
  return newSet;
};

const LdDashboardReducer = (state = initalState, action) => {
  switch (action.type) {
    case 'RESET_STATE':
      return {};
    case 'DUMMY_STATE':
      return {...state, isDummy: true};
    case 'ADD_TRACKED_MACHINE':
      return {
        ...state,
        trackedMachines: new Set([...state.trackedMachines, action.machineId]),
      };
    case 'REMOVE_TRACKED_MACHINE':
      return {
        ...state,
        trackedMachines: filter(state.trackedMachines, action.machineId),
      };
    default:
      return state;
  }

};

export default LdDashboardReducer;
