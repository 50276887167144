/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

'use strict';

import { css } from 'lit-element';

export const LdSidebarItemStyles = css`
  :host {
    display: block;
    height: 64px;
    border-left: 4px transparent solid;
    cursor: pointer;
  }
  
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  iron-icon {
    margin:20px 17px;
  }
`;

export default LdSidebarItemStyles;
