/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import {
  CLEAR_ADD_USER,
  CLEAR_DELETE_USER,
  CLEAR_EDIT_USER,
  CLEAR_USER_COMPANY_INFO,
  ERROR_ADD_USER,
  ERROR_DELETE_USER,
  ERROR_EDIT_USER,
  ERROR_LOADING_USER_COMPANY_INFO,
  ERROR_LOADING_USER_INFORMATION_OVER_ID,
  ERROR_LOADING_USER_LIST,
  ERROR_UPDATE_OWN_USER,
  ERROR_WRITE_USER_SETTINGS,
  FINISH_ADD_USER,
  FINISH_DELETE_USER,
  FINISH_EDIT_USER,
  FINISH_LOADING_USER_COMPANY_INFO,
  FINISH_LOADING_USER_INFORMATION_OVER_ID,
  FINISH_LOADING_USER_LIST,
  FINISH_UPDATE_OWN_USER,
  FINISH_WRITE_USER_SETTINGS,
  START_ADD_USER,
  START_DELETE_USER,
  START_EDIT_USER,
  START_LOADING_USER_COMPANY_INFO,
  START_LOADING_USER_INFORMATION_OVER_ID,
  START_LOADING_USER_LIST,
  START_UPDATE_OWN_USER,
  START_WRITE_USER_SETTINGS,
} from '../actions/ld-user-management-actions';

const LdUserManagementReducer = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_STATE':
      return {};
    case 'DUMMY_STATE':
      return {...state, isDummy: true};
    case START_LOADING_USER_LIST:
      return {
        ...state,
        isUserListLoading: true
      };
    case FINISH_LOADING_USER_LIST:
      return {
        ...state,
        userList: action.userList.users,
        isUserListLoading: false
      };
    case ERROR_LOADING_USER_LIST:
      return {
        ...state,
        result: action.error,
        isUserListLoading: false
      };
    // --------------------------------------------------------------------------------------
    case START_ADD_USER:
      return {
        ...state,
        isUserRequestLoading: true
      };
    case FINISH_ADD_USER:
      return {
        ...state,
        user: action.user,
        userRequestResult: true,
        userRequestType: 'new',
        isUserRequestLoading: false,
      };
    case ERROR_ADD_USER:
      return {
        ...state,
        result: action.error,
        userRequestResult: false,
        userRequestType: 'new',
        isUserRequestLoading: false
      };
    case CLEAR_ADD_USER:
      return {
        ...state,
        userRequestResult: null,
        userRequestType: 'new',
        result: null
      };

    // --------------------------------------------------------------------------------------
    case START_EDIT_USER:
      return {
        ...state,
        isUserRequestLoading: true
      };
    case FINISH_EDIT_USER:
      return {
        ...state,
        userEdit: action.userEdit,
        isUserRequestLoading: false,
        userRequestResult: true,
        userRequestType: 'edit'
      };
    case ERROR_EDIT_USER:
      return {
        ...state,
        isUserRequestLoading: false,
        result: action.error,
        userRequestType: 'edit'
      };
    case CLEAR_EDIT_USER:
      return {
        ...state,
        userRequestResult: null,
        userRequestType: 'edit',
        result: null
      };

    // --------------------------------------------------------------------------------------
    case START_DELETE_USER:
      return {
        ...state,
        isUserRequestLoading: true
      };
    case FINISH_DELETE_USER:
      return {
        ...state,
        deletedUser: action.deletedUser,
        isUserRequestLoading: false,
        userRequestResult: true,
        userRequestType: 'delete'
      };
    case ERROR_DELETE_USER:
      return {
        ...state,
        isUserRequestLoading: false,
        result: action.error,
        deletedUser: action.error,
        userRequestType: 'delete'
      };
    case CLEAR_DELETE_USER:
      return {
        ...state,
        userRequestResult: null,
        userRequestType: 'delete',
        deletedUser: null,
        result: null
      };

    // --------------------------------------------------------------------------------------
    case START_LOADING_USER_COMPANY_INFO:
      return {
        ...state,
        isUserCompanyInfoLoading: true
      };
    case FINISH_LOADING_USER_COMPANY_INFO:
      return {
        ...state,
        companyInfo: action.companyInfo,
        isUserCompanyInfoLoading: false
      };
    case CLEAR_USER_COMPANY_INFO:
      return {
        ...state,
        companyInfo: false,
        isUserCompanyInfoLoading: false,
        result: null,
        updatedUser: false,
        isUpdatedUser: false,
      };
    case ERROR_LOADING_USER_COMPANY_INFO:
      return {
        ...state,
        result: action.error,
        isUserCompanyInfoLoading: false
      };

    // --------------------------------------------------------------------------------------
    case START_LOADING_USER_INFORMATION_OVER_ID:
      return {
        ...state,
        isUserInfoIdLoading: true
      };
    case FINISH_LOADING_USER_INFORMATION_OVER_ID:
      return {
        ...state,
        userInfoId: action.userInfoId,
        isUserInfoIdLoading: false
      };
    case ERROR_LOADING_USER_INFORMATION_OVER_ID:
      return {
        ...state,
        result: action.error,
        isUserInfoIdLoading: false
      };

    // --------------------------------------------------------------------------------------
    case START_WRITE_USER_SETTINGS:
      return {
        ...state,
        isWriteUserSettings: true
      };
    case FINISH_WRITE_USER_SETTINGS:
      return {
        ...state,
        UserSettings: action.UserSettings,
        isWriteUserSettings: false
      };
    case ERROR_WRITE_USER_SETTINGS:
      return {
        ...state,
        result: action.error,
        isWriteUserSettings: false,
      };
    // --------------------------------------------------------------------------------------
    case START_UPDATE_OWN_USER:
      return {
        ...state,
        isUpdatedUser: true,
      };
    case FINISH_UPDATE_OWN_USER:
      return {
        ...state,
        updatedUser: true,
        isUpdatedUser: false,
      };
    case ERROR_UPDATE_OWN_USER:
      return {
        ...state,
        result: action.error,
        isUpdatedUser: false,
      };
    default:
      return state;
  }
};

export default LdUserManagementReducer;
