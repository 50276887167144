import { displayNotification } from "../LdNotificationHandler";

export interface NotificationHandler {
  notify(type: string, message: string, duration: number, dismissable: boolean): void;

}

export class ToastNotificationHandler implements NotificationHandler {
  notify(type: string, message: string, duration: number = 5000, dismissable: boolean = true) {
    displayNotification(type, message, duration , dismissable);
  }
}
