/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

'use strict';

import { unsafeCSS, css } from 'lit-element';
import { _THEME } from '../../themes/styles';
import { swColorPalette } from "../_base-styles/sw-color-palette";
import { idsColorPalette } from "../_base-styles/ids-color-palette";
import { theme_default } from "../_base-styles/theme_default";

export const LdApplicationStyles = css`

  ${unsafeCSS(swColorPalette)}
  ${unsafeCSS(idsColorPalette)}
  ${unsafeCSS(theme_default)}

  :host {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: auto;
  }
  ld-waiting {
    opacity: 1;
    transition: opacity 1s; 
  }

  ld-waiting.ld-fadeout {
    opacity: 0;
  }

  #login-iframe {
    display: none;
  }



`;

export default LdApplicationStyles;
