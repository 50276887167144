/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */
/* eslint-disable import/prefer-default-export */

import { css } from 'lit-element';

export const LdDatepickerStyles = css`
    :host {
      display: block;
      max-width: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }

`;
