/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { html, LitElement } from 'lit';
import {ifDefined} from 'lit/directives/if-defined.js';

import { LdI18NextMixin } from '../../ld-application/i18n/ld-i18next';
import { LdDatepickerStyles } from './styles';
import '@lifedata/ld-datepicker';
import moment from 'moment';

/**
 * @extends LitElement
 */
class LdDatepickerConfig extends LdI18NextMixin(LitElement) {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      startDate: { type: Boolean, hasChanged: () => true },
      addTitle: { type: String, hasChanged: () => true },
      noTimeSelect: { type: Boolean, hasChanged: () => true },
      singleDaySelect: { type: Boolean, hasChanged: () => true },
      noPresets: { type: Boolean, hasChanged: () => true },
      blockFutureDates: { type: Boolean, hasChanged: () => true },
      date: { type: String, hasChanged: () => true },
      maxSelectableDays: { type: Number, hasChanged: () => true },
      _settings: { type: Object, hasChanged: () => true },
      refreshDate: { type: String, hasChanged: () => true },
    };
  }

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdDatepickerStyles];
  }

  // --------------------------------------------------------------------------------------
  constructor() {
    super();
    this.refreshDate = null;
    this._settings = [
      {
        locale: {
          month: this.LdGetArray('modules.charts.globalSettings.lang.months'),
          monthsShort: this.LdGetArray('modules.charts.globalSettings.lang.shortMonths'),
          weekdays: this.LdGetArray('modules.charts.globalSettings.lang.weekdays'),
          weekdaysShort: this.LdGetArray('modules.charts.globalSettings.lang.shortWeekdays'),
          language: this.LdGetLanguage(),
          dateFormat: this.LdTranslate('modules.generic.dateFormat'),
          timeFormatWithoutSeconds: this.LdTranslate('modules.generic.timeFormatWithoutSeconds'),
          placeholderTitle: '',
          placeholderTitleActiveColor: 'var(--paper-input-container-focus-color, var(--primary-color))',
          placeholderTitleColor: 'var(--paper-input-container-color, var(--secondary-text-color))',
          rangeTo: this.LdTranslate('modules.datepicker.presets.rangeTo'),
          title: this.LdTranslate('modules.datepicker.presets.title'),
          submit: this.LdTranslate('modules.generic.buttons.submit'),
          cancel: this.LdTranslate('modules.generic.buttons.cancel'),
        },
      },
    ];
    this.blockFutureDates = false;
    this.singleDaySelect = false;
    this.noPresets = false;
    this.noTimeSelect = false;
    this.maxSelectableDays = 183;
    this.date = null;
    this.startDate = false;

  }

  // --------------------------------------------------------------------------------------
  firstUpdated() {
    setTimeout(() => {
      this._settings = [
        {
          presets: [
            {
              title: this.LdTranslate('modules.datepicker.presets.predefined.last5Minutes'),
              calc: {
                startDay: 'moment().subtract(5, "minutes")',
                endDay: 'moment()',
                startTime: {
                  hours: 'moment().hours()',
                  minutes: 'moment().minutes().subtract(5, "minutes")',
                },
                endTime: {
                  hours: 'moment().hours()',
                  minutes: 'moment().minutes()',
                },
              },
            },
            {
              title: this.LdTranslate('modules.datepicker.presets.predefined.last24Hours'),
              calc: {
                startDay: 'moment().subtract(24, "hours")',
                endDay: 'moment()',
                startTime: {
                  hours: 'moment().subtract(24, "hours").hours()',
                  minutes: 'moment().subtract(24, "hours").minutes()',
                },
                endTime: {
                  hours: 'moment().hours()',
                  minutes: 'moment().minutes()',
                },
              },
            },
            {
              title: this.LdTranslate('modules.datepicker.presets.predefined.today'),
              calc: {
                startDay: 'moment().startOf("day")',
                endDay: 'moment()',
                startTime: {
                  hours: '00',
                  minutes: '00',
                },
                endTime: {
                  hours: 'moment().hours()',
                  minutes: 'moment().minutes()',
                },
              },
            },
            {
              title: this.LdTranslate('modules.datepicker.presets.predefined.yesterday'),
              calc: {
                startDay: 'moment().subtract(1, "days")',
                endDay: 'moment().subtract(1, "days")',
                startTime: {
                  hours: 0,
                  minutes: 0,
                },
                endTime: {
                  hours: 23,
                  minutes: 59,
                },
              },
            },
            {
              title: this.LdTranslate('modules.datepicker.presets.predefined.last7days'),
              calc: {
                startDay: 'moment().subtract(7, "days")',
                endDay: 'moment()',
                startTime: {
                  hours: 'moment().subtract(7, "days").hours()',
                  minutes: 'moment().subtract(7, "days").minutes()',
                },
                endTime: {
                  hours: 'moment().hours()',
                  minutes: 'moment().minutes()',
                },
              },
            },
          ],
          locale: {
            month: this.LdGetArray('modules.charts.globalSettings.lang.months'),
            monthsShort: this.LdGetArray('modules.charts.globalSettings.lang.shortMonths'),
            weekdays: this.LdGetArray('modules.charts.globalSettings.lang.weekdays'),
            weekdaysShort: this.LdGetArray('modules.charts.globalSettings.lang.shortWeekdays'),
            language: this.LdGetLanguage(),
            dateFormat: this.LdTranslate('modules.generic.dateFormat'),
            timeFormatWithoutSeconds: this.LdTranslate('modules.generic.timeFormatWithoutSeconds'),
            placeholderTitle: '',
            placeholderTitleActiveColor: 'var(--paper-input-container-focus-color, var(--primary-color))',
            placeholderTitleColor: 'var(--paper-input-container-color, var(--secondary-text-color))',
            rangeTo: this.LdTranslate('modules.datepicker.presets.rangeTo'),
            title: this.LdTranslate('modules.datepicker.presets.title'),
            submit: this.LdTranslate('modules.generic.buttons.submit'),
            cancel: this.LdTranslate('modules.generic.buttons.cancel'),
          },
          refresh: {
            startDay: this.refreshDate != null ? `${this.refreshDate}` : 'moment().subtract(24, "hours")',
            endDay: 'moment()',
            startTime: {
              hours: 'moment().subtract(24, "hours").hours()',
              minutes: 'moment().subtract(24, "hours").minutes()',
            },
            endTime: {
              hours: 'moment().hours()',
              minutes: 'moment().minutes()',
            },
          },
        },
      ];
    }, 1000);
  }

  // --------------------------------------------------------------------------------------
  render() {
    if (this.addTitle != null) {
      this._settings[0].locale.placeholderTitle = this.addTitle;
    }
    return html`
      <ld-datepicker 
        date="${ifDefined(this.date)}"
        maxSelectableDays="${this.maxSelectableDays}" 
        @changed="${(evt) => this._onEvent(evt)}" 
        .settings='${this._settings}' 
        ?noTimeSelect="${this.noTimeSelect}" 
        ?singleDaySelect="${this.singleDaySelect}" 
        ?noPresets="${this.noPresets}" 
        ?blockFutureDates="${this.blockFutureDates}"
        ?startDate="${this.startDate}"
      > </ld-datepicker>
    `;
  }

  // --------------------------------------------------------------------------------------
  _onEvent(evt) {
    // @ts-ignore
    const clonedEvent = new evt.constructor(evt.type, evt);
    this.dispatchEvent(clonedEvent);
  }

}

customElements.define('ld-datepicker-config', LdDatepickerConfig);
