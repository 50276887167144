/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { LitElement, html } from 'lit-element';

import { connect }               from 'pwa-helpers/connect-mixin';
import { installRouter }         from 'pwa-helpers/router';
import { installOfflineWatcher } from 'pwa-helpers/network';
import { LdI18NextMixin }        from './i18n/ld-i18next';
import LdError                   from '../../js/LdErrorHandler';

import '../ld-waiting';
import '../ld-platform/ld-platform';
import '../ld-loading-spinner/ld-loading-spinner';

import LdLog from '../../js/LdLog';

import { store }                                         from '../ld-redux/ld-redux-store';
import { loadUserInformation, checkPrivacyPolicyStatus } from '../ld-redux/actions/ld-users-actions';
import { updateLocation }                                from '../ld-redux/actions/ld-application-actions';
import { LdApplicationStyles }                           from './ld-application-styles';

import '@polymer/paper-button/paper-button';

import './ld-privacy-policy';
import '../../views/forgot-password/create-new-password-view';
import '../../views/forgot-password/forgot-password-view';
import '../../views/change-password/change-password-view';
import '../../views/login/login-view';
import LdFetch from '../../js/LdFetch';
import { CONFIG, redirectLogin } from '../../js/LdGlobal';

/**
 * Entrypoint for the SW Cloud Platform
 * generates a &lt;ld-application&gt; component
 *
 * @extends LitElement
 * @mixes connect
 * @mixes LdI18NextMixin
 */
class LdApplication extends connect(store)<any>(LdI18NextMixin(LitElement)) {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      _userInfo: { type: Object },
      _isUserInfoLoading: { type: Boolean },
      _checkInterval: { type: Number },
      _language: { type: String },
      _interval: { type: Number, hasChanged: () => true },
      _url: { type: Object },
    };
  }

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdApplicationStyles];
  }

  // --------------------------------------------------------------------------------------
  constructor() {
    super();
    this._interval = null;
    this._url = null;
    LdFetch.addEventListener("401", this.handleUnauthorizedUser);
    LdFetch.addEventListener("403", this.handleUnauthorizedUser);
  }

  handleUnauthorizedUser(_event: any) {
    if ((/netcfg/i).test(window.location.pathname)) {
      // it is ok if we dont redirect here
    } else {
      localStorage.removeItem(CONFIG.localstorage.token_key);
      redirectLogin('session_timeout');
    }
  }

  // --------------------------------------------------------------------------------------
  private clearTokenOnRedirectFromLogin() {
    const queryString = window.location.href;
    const query       = queryString.split('?');

    if (query != null && query.includes('loggedIn')) {
      const currentUrl = new URL(window.location.href.replace('?loggedIn', ''));
      const newUrl     = currentUrl.href;
      // this updates the URL without reloading the page. See https://stackoverflow.com/questions/10970078
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }

  // --------------------------------------------------------------------------------------
  async setupUserDataChecks() {
    this.clearTokenOnRedirectFromLogin();

    store.dispatch(loadUserInformation());
    store.dispatch(checkPrivacyPolicyStatus());
  }

  // --------------------------------------------------------------------------------------
  disconnectedCallback() {
    clearInterval(this._checkInterval);
    clearInterval(this._interval);
  }

  /**
   * render method, used to display the rendered template
   *
   * @returns {String} Rendered literals template
   */
  // --------------------------------------------------------------------------------------
  render() {
    let loadedPageModule;

    if (this._url != null) {
      loadedPageModule = this._checkUrlRenderOutput(this._url);
    }

    return html`
      ${loadedPageModule}
      <ld-waiting></ld-waiting>
      <ld-spinner></ld-spinner>
     
    `;
  }

  // --------------------------------------------------------------------------------------
  firstUpdated() {
    this._url = new URL(window.location.href);

    if (!this._url.pathname.includes('login') && !this._url.pathname.includes('password')) {
      this.setupUserDataChecks();

    } else {
      this._isUserInfoLoading = false;
      this._onUserInfoLoadingChanged(false);
    }

    setTimeout(() => {
      installRouter(location => store.dispatch(updateLocation(location)));

      installOfflineWatcher(offline => {
        LdError._handleError(offline ? 'no_connection' : '', undefined);
      });
    }, 500);


  }

  /**
   * @protected
   * @param {JSON} - updated state handled by the redux implementation
   */
  // --------------------------------------------------------------------------------------
  stateChanged(state: any) {
    if(this._url !== new URL(window.location.href)){
      this._url = new URL(window.location.href);
    }

    const userInfo = state.LdUsersReducer.info;
    let oldLanguage;
    if (userInfo) {
      if (localStorage.getItem(`language_${userInfo.id}`) != null && localStorage.getItem(`language_${userInfo.id}`) !== userInfo.locale) {
        oldLanguage    = this._language;
        this._language = localStorage.getItem(`language_${userInfo.id}`);
      } else {
        this._onUserInfoObjectChanged(userInfo);
        this._userInfo = userInfo;
        oldLanguage    = this._language;
        this._language = userInfo.locale;
      }
    }

    const userInfoLoading   = state.LdUsersReducer.isUserInfoLoading;
    this._isUserInfoLoading = userInfoLoading;
    if (userInfoLoading === false) {
      this._onUserInfoLoadingChanged(userInfoLoading);
    }
    if (
      this._userInfo &&
      oldLanguage !== this._language
    ) {
      this.LdChangeLanguage(this._language, (err: any) => {
        if (err) {
          LdLog.error('error changing language', err);
        }
      });
    }
  }

  /**
   * removes the &lt;ld-waiting&gt; element after the user info has finished loading
   *
   * @protected
   * @param {JSON} userInfo - the updated user info object
   */
  // --------------------------------------------------------------------------------------
  _onUserInfoObjectChanged(userInfo: any) {
    if (typeof userInfo !== 'undefined' && userInfo !== null && this.shadowRoot.querySelector('ld-platform') === null) {
      this.insertPlatformNode();

      if (typeof this._isUserInfoLoading !== 'undefined' && this._isUserInfoLoading === false) {
        this.__hideWaitingElement();
      }
    }
  }

  private insertPlatformNode() {
    const existingPlatform = this.shadowRoot.querySelector('ld-platform');
    if (existingPlatform) return;

    const ldPlatformElement = document.createElement('ld-platform');
    this.shadowRoot.appendChild(ldPlatformElement);
  }

  /**
   * removes the &lt;ld-waiting&gt; element after the user info loading state has changed
   *
   * @protected
   * @param {Boolean} isUserInfoLoading - is true if the user info is still loading
   */
  // --------------------------------------------------------------------------------------
  _onUserInfoLoadingChanged(isUserInfoLoading: boolean) {
    if (typeof isUserInfoLoading !== 'undefined' && isUserInfoLoading === false) {
      this.__hideWaitingElement();
    }
  }

  // --------------------------------------------------------------------------------------
  __hideWaitingElement() {
    const ldWaitingElement = this.shadowRoot.querySelector('ld-waiting');
    if (ldWaitingElement !== null) {
      ldWaitingElement.classList.add('ld-fadeout');
      setTimeout(() => {
        ldWaitingElement.remove();
      }, 1000);
    }
  }

  // --------------------------------------------------------------------------------------
  _checkUrlRenderOutput(url: URL) {
    let loadedPageModule;
    switch (true) {
      case (/netcfg/i.test(url.pathname)):

        if (this.userIsAuthenticated()) break;

        loadedPageModule = html`
          <ld-netcfg standalone class='_pageModule' active picture='../../../images/service/hotlinePerson.svg'></ld-netcfg>`;

        break;
      case (/login/i.test(url.pathname)):
        this._showLoginScreen = true;
        loadedPageModule      = html`
          <ld-login-application >
            <login-view class='_pageModule' active></login-view>
          </ld-login-application>`;
        break;
      case (/forgot-password/i.test(url.pathname)):
        this._showLoginScreen = true;
        loadedPageModule      = html`
          <ld-login-application>
            <forgot-password-view class='_pageModule' active></forgot-password-view>
          </ld-login-application>`;
        break;
      case (/new-password/i.test(url.pathname)):
        this._showLoginScreen = true;
        loadedPageModule      = html`
          <ld-login-application>
            <create-new-password-view class='_pageModule' active></create-new-password-view>
          </ld-login-application>`;
        break;
      case (/change-password/i.test(url.pathname)):
        this._showLoginScreen = true;
        loadedPageModule      = html`
          <ld-login-application>
            <change-password-view class='_pageModule' active></change-password-view>
          </ld-login-application>`;
        break;
      default: {
        if (!this.userIsAuthenticated()) break;
        this.insertPlatformNode();
        break;
      }

    }
    return loadedPageModule;
  }

  private userIsAuthenticated() {
    return this._userInfo?.role;
  }

}

customElements.define('ld-application', LdApplication as unknown as CustomElementConstructor);
