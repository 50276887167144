/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

// eslint-disable-next-line import/no-cycle
import {
  ACK_ERROR_CHANGING_LIMITS,
  CLEAR_ADD_ASSET,
  CLEAR_DELETE_ASSET,
  CLEAR_EDIT_ASSET,
  EMPTY_ASSETS,
  ERROR_ADD_ASSET,
  ERROR_ASSET_DELETE,
  ERROR_CHANGING_LIMITS,
  ERROR_COORDINATES_BY_ADDRESS,
  ERROR_DASHBOARD_LINES,
  ERROR_EDIT_ASSET,
  ERROR_LOADING_ASSETS,
  ERROR_LOADING_MACHINES_BY_MACHINETYPE,
  ERROR_READ_CUSTOMER_NUMBER,
  ERROR_SHOPFLOOR_DELETE,
  ERROR_SHOPFLOOR_LINK,
  ERROR_SHOPFLOOR_LIST,
  FINISH_ADD_ASSET,
  FINISH_ASSET_DELETE,
  FINISH_CHANGING_LIMITS,
  FINISH_COORDINATES_BY_ADDRESS,
  FINISH_DASHBOARD_LINES,
  FINISH_EDIT_ASSET,
  FINISH_LOADING_ASSETS,
  FINISH_LOADING_MACHINES_BY_MACHINETYPE,
  FINISH_READ_CUSTOMER_NUMBER,
  FINISH_SHOPFLOOR_DELETE,
  FINISH_SHOPFLOOR_LINK,
  FINISH_SHOPFLOOR_LIST,
  RESET_COORDINATES,
  START_ADD_ASSET,
  START_ASSET_DELETE,
  START_CHANGING_LIMITS,
  START_COORDINATES_BY_ADDRESS,
  START_DASHBOARD_LINES,
  START_EDIT_ASSET,
  START_LOADING_ASSETS,
  START_LOADING_MACHINES_BY_MACHINETYPE,
  START_READ_CUSTOMER_NUMBER,
  START_SHOPFLOOR_DELETE,
  START_SHOPFLOOR_LINK,
  START_SHOPFLOOR_LIST,
} from '../actions/ld-assets-actions';

const LdAssetsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_STATE':
      return {};
    case 'DUMMY_STATE':
      return {...state, isDummy: true};
    case EMPTY_ASSETS:
      return {
        ...state,
        companies: null,
        company: null,
        locations: null,
        location: null,
        halls: null,
        hall: null,
        lines: null,
        line: null,
        machines: null,
        machine: null,
        shopfloor: null,
        shopfloorCardObject: null,
        shopfloorList: null,
        shopfloorPosLink: null,
        shopfloorDelete: null,
        dashboardCards: null,
        finishedDownloads: null,
        errorDownloads: null,
      };
    case START_LOADING_ASSETS:

      switch (action.assetType) {
        case 'companies':
          return {
            ...state,
            loadingCompanies: true,
          };
        case 'company':
          return {
            ...state,
            loadingCompany: true,
          };
        case 'locations':
          return {
            ...state,
            loadingLocations: true,
          };
        case 'location':
          return {
            ...state,
            loadingLocation: true,
          };
        case 'halls':
          return {
            ...state,
            loadingHalls: true,
          };
        case 'hall':
          return {
            ...state,
            loadingHall: true,
          };
        case 'lines':
          return {
            ...state,
            loadingLines: true,
          };
        case 'line':
          return {
            ...state,
            loadingLine: true,
          };
        case 'machines':
          return {
            ...state,
            loadingMachines: true,
          };
        case 'machine':
          return {
            ...state,
            loadingMachine: true,
          };
        case 'machineTypes':
          return {
            ...state,
            loadingMachineTypes: true,
          };
        case 'controllers':
          return {
            ...state,
            loadingControllers: true,
          };
        case 'variables':
          return {
            ...state,
            loadingVariables: true,
          };
        case 'collections':
          return {
            ...state,
            loadingCollections: true,
          };
        case 'countries':
          return {
            ...state,
            loadingCountries: true,
          };
        case 'industrySectors':
          return {
            ...state,
            loadingIndustrySectors: true,
          };
        case 'dashboardShopfloorCard':
          return {
            ...state,
            loadingShopfloorCard: true,
          };
        case 'dashboardSpistatCard':
          return {
            ...state,
            loadingSpistatCard: true,
          };
        case 'dashboardKpiCard':
          return {
            ...state,
            loadingKpiCard: true,
          };
        case 'dashboardInProductionCard':
          return {
            ...state,
            loadingInProductionCard: true,
          };
        case 'dashboardWorldmapCard':
          return {
            ...state,
            loadingDashboardWorldmap: true,
          };
        case 'shopfloorPosLink':
          return {
            ...state,
            loadingShopfloorPosLink: true,
          };
        case 'posLink':
          return {
            ...state,
            loadingShopfloor: true,
          };
        case 'imageShopfloorCard':
          return {
            ...state,
            loadingShopfloorCard: true,
          };
        default:
          return state;
      }

    case FINISH_LOADING_ASSETS:
      switch (action.assetType) {
        case 'companies':
          const sortCompaniesAlphabetically = (companies) => {
            return [...companies].sort((a, b) => a.name.localeCompare(b.name));
          };
          return {
            ...state,
            loadingCompanies: false,
            companies: sortCompaniesAlphabetically(action.assetsList),
          };
        case 'company':
          return {
            ...state,
            loadingCompany: false,
            company: action.assetsList,
          };
        case 'locations':
          return {
            ...state,
            loadingLocations: false,
            locations: action.assetsList,
          };
        case 'location':
          return {
            ...state,
            loadingLocation: false,
            location: action.assetsList,
          };
        case 'halls':
          return {
            ...state,
            loadingHalls: false,
            halls: action.assetsList,
          };
        case 'hall':
          return {
            ...state,
            loadingHall: false,
            hall: action.assetsList,
          };
        case 'lines':
          return {
            ...state,
            loadingLines: false,
            lines: action.assetsList,
          };
        case 'line':
          return {
            ...state,
            loadingLine: false,
            line: action.assetsList,
          };
        case 'machines':
          return {
            ...state,
            loadingMachines: false,
            machines: action.assetsList,
          };
        case 'machine':
          return {
            ...state,
            loadingMachine: false,
            machine: action.assetsList,
          };
        case 'machineTypes':
          return {
            ...state,
            loadingMachineTypes: false,
            machineTypes: action.assetsList,
          };
        case 'controllers':
          return {
            ...state,
            loadingControllers: false,
            controllers: action.assetsList,
          };
        case 'variables':
          return {
            ...state,
            loadingVariables: false,
            variables: action.assetsList,
          };
        case 'collections':
          return {
            ...state,
            loadingCollections: false,
            collections: action.assetsList,
          };
        case 'countries':
          return {
            ...state,
            loadingCountries: false,
            countries: action.assetsList,
          };
        case 'industrySectors':
          return {
            ...state,
            loadingIndustrySectors: false,
            industrySectors: action.assetsList,
          };
        case 'dashboardShopfloorCard':
          return {
            ...state,
            loadingShopfloorCard: false,
            shopfloorCardObject: action.assetsList,
          };
        case 'dashboardSpistatCard':
          return {
            ...state,
            loadingSpistatCard: false,
            spistatCardObject: action.assetsList,
          };
        case 'dashboardKpiCard':
          return {
            ...state,
            loadingKpiCard: false,
            kpiCardObject: action.assetsList,
          };
        case 'dashboardInProductionCard':
          return {
            ...state,
            loadingInProductionCard: false,
            inProductionCardObject: action.assetsList,
          };
        case 'dashboardWorldmapCard':
          return {
            ...state,
            loadingDashboardWorldmap: false,
            dashboardWorldmapLocations: action.assetsList,
          };
        case 'shopfloorPosLink':
          return {
            ...state,
            loadingShopfloorPosLink: false,
            shopfloorPosLink: action.assetsList,
          };
        case 'posLink':
          return {
            ...state,
            loadingShopfloor: false,
            shopfloor: action.assetsList,
          };
        case 'imageShopfloorCard':
          return {
            ...state,
            loadingShopfloorCard: false,
            shopfloorCardObject: action.assetsList,
          };
        default:
          return state;
      }

    case ERROR_LOADING_ASSETS:
      switch (action.assetType) {
        case 'companies':
          return {
            ...state,
            result: action.error,
            loadingCompanies: false,
          };
        case 'company':
          return {
            ...state,
            result: action.error,
            loadingCompany: false,
          };
        case 'locations':
          return {
            ...state,
            result: action.error,
            loadingLocations: false,
          };
        case 'location':
          return {
            ...state,
            result: action.error,
            loadingLocation: false,
          };
        case 'halls':
          return {
            ...state,
            result: action.error,
            loadingHalls: false,
          };
        case 'hall':
          return {
            ...state,
            result: action.error,
            loadingHall: false,
          };
        case 'lines':
          return {
            ...state,
            result: action.error,
            loadingLines: false,
          };
        case 'line':
          return {
            ...state,
            result: action.error,
            loadingLine: false,
          };
        case 'machines':
          return {
            ...state,
            result: action.error,
            loadingMachines: false,
          };
        case 'machine':
          return {
            ...state,
            result: action.error,
            loadingMachine: false,
          };
        case 'machineTypes':
          return {
            ...state,
            result: action.error,
            loadingMachineTypes: false,
          };
        case 'controllers':
          return {
            ...state,
            result: action.error,
            loadingControllers: false,
          };
        case 'countries':
          return {
            ...state,
            result: action.error,
            loadingCountries: false,
          };
        case 'industrySectors':
          return {
            ...state,
            result: action.error,
            loadingIndustrySectors: false,
          };
        case 'variables':
          return {
            ...state,
            result: action.error,
            loadingVariables: false,
          };
        case 'collections':
          return {
            ...state,
            result: action.error,
            loadingCollections: false,
          };
        case 'dashboardShopfloorCard':
          return {
            ...state,
            result: action.error,
            loadingShopfloorCard: false,
          };
        case 'dashboardSpistatCard':
          return {
            ...state,
            loadingSpistatCard: false,
          };
        case 'dashboardKpiCard':
          return {
            ...state,
            result: action.error,
            loadingKpiCard: false,
          };
        case 'dashboardInProductionCard':
          return {
            ...state,
            result: action.error,
            loadingInProductionCard: false,
          };
        case 'dashboardWorldmapCard':
          return {
            ...state,
            result: action.error,
            loadingDashboardWorldmap: false,
          };
        case 'shopfloorPosLink':
          return {
            ...state,
            loadingShopfloorPosLink: false,
            result: action.error,
          };
        case 'posLink':
          return {
            ...state,
            result: action.error,
            loadingShopfloor: false,
          };
        case 'imageShopfloorCard':
          return {
            ...state,
            loadingShopfloorCard: false,
            result: action.error,
          };
        default:
          return state;
      }

    //--------------------------------------------------------------------------------
    case START_LOADING_MACHINES_BY_MACHINETYPE:
      return {
        ...state,
        machinesListLoading: true,
      };
    case FINISH_LOADING_MACHINES_BY_MACHINETYPE:
      return {
        ...state,
        machinesList: action.machinesList,
        machinesListLoading: false,
      };
    case ERROR_LOADING_MACHINES_BY_MACHINETYPE:
      return {
        ...state,
        result: action.error,
        machinesListLoading: false,
      };

    //--------------------------------------------------------------------------------
    case START_DASHBOARD_LINES:
      return {
        ...state,
        dashboardLinesLoading: true,
      };
    case FINISH_DASHBOARD_LINES:
      return {
        ...state,
        dashboardCards: action.cards,
        dashboardLinesLoading: false,
      };
    case ERROR_DASHBOARD_LINES:
      return {
        ...state,
        result: action.error,
        dashboardLinesLoading: false,
      };

    //--------------------------------------------------------------------------------
    case START_COORDINATES_BY_ADDRESS:
      return {
        ...state,
        isAssetsCoordinatesByAddressLoading: true,
      };
    case FINISH_COORDINATES_BY_ADDRESS:
      return {
        ...state,
        coordinates: action.res,
        isAssetsCoordinatesByAddressLoading: false,
      };
    case ERROR_COORDINATES_BY_ADDRESS:
      return {
        ...state,
        result: action.error,
        isAssetsCoordinatesByAddressLoading: false,
      };

    //-------------------------------------------------------------------------------
    case START_ADD_ASSET:
      switch (action.assetType) {
        case 'new':
          return {
            ...state,
            isAssetsNewLoading: true,
          };
        default:
          return state;
      }
    case FINISH_ADD_ASSET:
      switch (action.assetType) {
        case 'new':
          return {
            ...state,
            isAssetsNewLoading: false,
            addAssetValue: action.addAssetValue,
          };
        default:
          return state;
      }
    case ERROR_ADD_ASSET:
      switch (action.assetType) {
        case 'new':
          return {
            ...state,
            isAssetsNewLoading: false,
            result: action.error,
          };
        default:
          return state;
      }
    case CLEAR_ADD_ASSET:
      return {
        ...state,
        addAssetValue: null,
        result: null,
      };
    case CLEAR_DELETE_ASSET:
      return {
        ...state,
        assetDelete: null,
        result: null,
      };

    //--------------------------------------------------------------------------------
    case START_EDIT_ASSET:
      switch (action.assetType) {
        case 'edit':
          return {
            ...state,
            isAssetsEditLoading: true,
            isSavingChangedData: true
          };
        default:
          return state;
      }
    case FINISH_EDIT_ASSET:
      switch (action.assetType) {
        case 'edit':
          return {
            ...state,
            isAssetsEditLoading: false,
            isSavingChangedData: false,
            editAssetValue: action.editAssetValue,
          };
        default:
          return state;
      }
    case ERROR_EDIT_ASSET:
      switch (action.assetType) {
        case 'edit':
          return {
            ...state,
            isAssetsEditLoading: false,
            isSavingChangedData: false,
            result: action.error,
          };
        default:
          return state;
      }
    case CLEAR_EDIT_ASSET:
      return {
        ...state,
        isAssetsEditLoading: false,
        editAssetValue: null,
        result: null,
        limitChangeState: null,
        isSavingChangedData: null
      };

    //--------------------------------------------------------------------------------
    case START_READ_CUSTOMER_NUMBER:
      return {
        ...state,
        machineId: action.machineId,
        isCustomerNumberLoading: true,
      };
    case FINISH_READ_CUSTOMER_NUMBER:
      return {
        ...state,
        machineId: action.machineId,
        isCustomerNumberLoading: false,
        customerNumber: action.customerNumber,
      };
    case ERROR_READ_CUSTOMER_NUMBER:
      return {
        ...state,
        machineId: action.machineId,
        isCustomerNumberLoading: false,
        result: action.error,
      };

    //--------------------------------------------------------------------------------
    case START_SHOPFLOOR_LIST:
      return {
        ...state,
        isShopfloorLoading: true,
      };
    case FINISH_SHOPFLOOR_LIST:
      return {
        ...state,
        shopfloorList: action.shopfloorList,
        isShopfloorLoading: false,
      };
    case ERROR_SHOPFLOOR_LIST:
      return {
        ...state,
        result: action.error,
        isShopfloorLoading: false,
      };

    //--------------------------------------------------------------------------------
    case START_SHOPFLOOR_LINK:
      return {
        ...state,
        isShopfloorLinkLoading: true,
      };
    case FINISH_SHOPFLOOR_LINK:
      return {
        ...state,
        shopfloorLink: action.shopfloorLink,
        isShopfloorLinkLoading: false,
      };
    case ERROR_SHOPFLOOR_LINK:
      return {
        ...state,
        result: action.error,
        isShopfloorLinkLoading: false,
      };

    //--------------------------------------------------------------------------------
    case START_SHOPFLOOR_DELETE:
      return {
        ...state,
        isShopfloorDeleteLoading: true,
      };
    case FINISH_SHOPFLOOR_DELETE:
      return {
        ...state,
        shopfloorDelete: action.shopfloorDelete,
        isShopfloorDeleteLoading: false,
      };
    case ERROR_SHOPFLOOR_DELETE:
      return {
        ...state,
        result: action.error,
        isShopfloorDeleteLoading: false,
      };

    //--------------------------------------------------------------------------------
    case START_ASSET_DELETE:
      return {
        ...state,
        isAssetDeleteLoading: true,
      };
    case FINISH_ASSET_DELETE:
      return {
        ...state,
        assetDelete: action.assetDelete,
        isAssetDeleteLoading: false,
      };
    case ERROR_ASSET_DELETE:
      return {
        ...state,
        result: action.error,
        isAssetDeleteLoading: false,
      };
    case RESET_COORDINATES:
      return {
        ...state,
        coordinates: undefined,
      };
    case START_CHANGING_LIMITS:
      return {
        ...state,
        limitChangeState: "changing",
      };
    case FINISH_CHANGING_LIMITS:
      return {
        ...state,
        limitChangeState: "changed",
      };
    case ERROR_CHANGING_LIMITS:
      return {
        ...state,
        limitChangeState: "failed",
      };
    case ACK_ERROR_CHANGING_LIMITS:
      return {
        ...state,
        limitChangeState: undefined,
      };

    case "DOWNLOAD-FINISHED":
      const alreadyFinished = state.finishedDownloads ? [...state.finishedDownloads] : []
      return {
        ...state,
        finishedDownloads: [...alreadyFinished, action.downloadId],
      };
    case 'DOWNLOAD-ERROR':
      const error = state.errorDownloads ? [...state.errorDownloads] : [];
      return {
        ...state,
        errorDownloads: [...error, action.downloadId],
      };
    default:
      return state;
  }
};

export default LdAssetsReducer;
