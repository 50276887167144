import { CONFIG } from "../LdGlobal";

export interface Validator {
  validate(formFields: Array<any>): Array<boolean>;
}

export class BrowserFormValidator {

  validate(formFields: any[]) {
    /**
     * validate form data
     */
    formFields.forEach((field, index) => {
      if (typeof field.fieldDefinition.validation !== 'undefined') {

        const fieldValue = (typeof field.value === 'undefined') ? '' : field.value;
        let isValid = true;

        field.fieldDefinition.validation.forEach((rule: string) => {
          if (rule === 'isNotEmpty') {
            isValid = !!fieldValue;
          } else if (rule === 'fileSizeLimit') {
            if (fieldValue !== '') {
              const maxSizeInMB = CONFIG.backend.services.service.maxUploadSizeInMB;
              const maxSizeInB = maxSizeInMB * 1048576;
              isValid = fieldValue.size != null ? fieldValue.size < maxSizeInB : true;
            }
          }
        });

        formFields[index].isValid = isValid;
      }
    });

    formFields.forEach(field => {
      field.domNode.invalid = typeof field.isValid !== 'undefined' && field.isValid !== null && !field.isValid;
    });

    return formFields.filter(field => !field.isValid);
  }

}

export class PermissiveFormValidator {

  validate(formFields: any[]) {
    /**
     * validate form data
     */
    formFields.forEach((field, index) => {
      if (typeof field.fieldDefinition.validation !== 'undefined') {

        formFields[index].isValid = true;
      }
    });

    formFields.forEach(field => {
      field.domNode.invalid = !field.isValid;
    });

    return [];
  }

}
