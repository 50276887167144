import { css } from "lit";

export const swColorPalette = css`

  /* These are the colors according to https://bam.sw-machines.com/de/02-gestaltung/corporate-design/farbsystem */
  /* Currently only a subset is listed here. These are the ones currently used. */
  :host {

    --sw-primary-red: #E81C3D;
    --sw-primary-creme: #ECECE9;
    --sw-primary-white: #ffffff;
    --sw-primary-black: #000000;
    --sw-primary-gray-one: #f2f2f2;
    --sw-primary-gray-two: #828282;
    --sw-primary-gray-three: #323232;

    --sw-secondary-green-100: #DAD6BF;
    --sw-secondary-green-200: #BBB487;
    --sw-secondary-green-300: #3f8000;
    --sw-secondary-green-400: #7E761A;
    --sw-secondary-green-500: #676223;
    --sw-secondary-green-600: #4D4B1A;

  }

`
