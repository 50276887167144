import LdI18Next from '../i18n/ld-i18next';

export const LdMessages: any = {
    '400': {
        'error': true,
        'http_status_code': 400,
        'internal_status_code': null,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_400.message'),
    },
    '401': {
        'error': true,
        'http_status_code': 401,
        'internal_status_code': null,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_401.message'),
    },
    '403': {
        'error': true,
        'http_status_code': 403,
        'internal_status_code': null,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_403.message'),
    },
    '404': {
        'error': true,
        'http_status_code': 404,
        'internal_status_code': null,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_404.message'),
    },
    '405': {
        'error': true,
        'http_status_code': 405,
        'internal_status_code': null,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_405.message'),
    },
    '409': {
        'error': true,
        'http_status_code': 409,
        'internal_status_code': null,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_409.message'),
    },
    '415': {
        'error': true,
        'http_status_code': 415,
        'internal_status_code': null,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_0.message'),
    },
    '418': {
        'error': true,
        'http_status_code': 418,
        'internal_status_code': null,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_418.message'),
    },
    '500': {
        'error': true,
        'http_status_code': 500,
        'internal_status_code': null,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_500.message'),
    },
    'asset_create_error': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10900,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_900.message'),
    },
    'cancel_current_new_asset_action': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10901,
        'toast_duration': 5000,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_901.message'),
    },
    'cancel_service_request': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10902,
        'toast_duration': 5000,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_902.message'),
    },
    'asset_409': {
        'error': true,
        'http_status_code': 409,
        'internal_status_code': null,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_409_asset.message'),
    },
    'service_request_error': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10903,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_903.message'),
    },
    'asset_insert_success': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10904,
        'toast_duration': 5000,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_904.message'),
    },
    'cancel_current_edit_asset_action': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10905,
        'toast_duration': 5000,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_905.message'),
    },
    'insert_error': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10910,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_910.message'),
    },
    'cancel_current_user_action': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10911,
        'toast_duration': 5000,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_911.message'),
    },
    'asset_update_error': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10912,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_912.message'),
    },
    'cancel_current_action': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10920,
        'toast_duration': 5000,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_920.message'),
    },
    'unspecified': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10921,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_921.message'),
    },
    'wrong_credentials': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10922,
        'toast_duration': 0,
        'highlight_input': true,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_922.message'),
    },
    'no_credentials': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10923,
        'toast_duration': 0,
        'highlight_input': true,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_923.message'),
    },
    'session_timeout': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10924,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_924.message'),
    },
    'policy_not_accepted': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10925,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_925.message'),
    },
    'locked': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10926,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_926.message'),
    },
    'password_change_successful': {
        'error': false,
        'http_status_code': 201,
        'internal_status_code': 10927,
        'toast_duration': 5000,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_927.message'),
    },
    'cancel_upload_file': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10928,
        'toast_duration': 5000,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_928.message'),
    },
    'upload_file_error': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10929,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_929.message'),
    },
    'no_connection': {
        'error': false,
        'http_status_code': null,
        'internal_status_code': 10500,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_10500.message'),
    },
    '502': {
        'error': false,
        'http_status_code': null,
        'internal_status_code': 10500,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': false,
        'message': LdI18Next.t('modules.errors.statusCodes.e_10500.message'),
    },
    'not_supported_browser': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10501,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.errors.statusCodes.e_10501.message'),
    },
    'add_line_to_dashboard': {
        'error': false,
        'http_status_code': null,
        'internal_status_code': 10502,
        'toast_duration': 5000,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.success.message.add_line_to_dashboard'),
    },
    'duplication_deleted': {
        'error': false,
        'http_status_code': null,
        'internal_status_code': 10503,
        'toast_duration': 5000,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.success.message.duplication_deleted'),
    },
    'enrollment_code_409': {
        'error': true,
        'http_status_code': null,
        'internal_status_code': 10504,
        'toast_duration': 0,
        'highlight_input': false,
        'disable_input': true,
        'message': LdI18Next.t('modules.error.message.enrollment_code_409'),
    },
    'NotAuthorizedException': {
        'error': true,
        'http_status_code': 400,
        'message': 'Your old password is incorrect. Please try again.',
    },
    'change_pw_required': {
        'message': 'Before your first login, you need to change your temporary password.',
    },
    'force_change_password_success': {
        'error': false,
        'toast_duration': 5000,
        'message_type': 'SUCCESS',
        'message': 'Your password has been changed successfully.',
        'link_text': 'Please click here to continue',
    },
    'force_change_password_error': {
        'error': true,
        'toast_duration': 0,
        'message_type': 'ERROR',
        'message': 'Your password could not be changed. Please try again.',
    },
    'verify_email': {
        'message': 'Please enter the verification code that has been sent to your e-mail address.',
    },
    'UserNotConfirmedException': {
        'error': true,
        'http_status_code': 401,
        'internal_status_code': null,
        'toast_duration': 0,
        'highlight_input': true,
        'disable_input': false,
        'message': 'You first need to verify your account. Therefore an verification code has been sent to your e-mail account.',
    },
    'forgot_password_code_sent': {
        'message': 'A verification code has been sent to your e-mail address. Please use this code to set a new password.',
    },
    'no_verification_code_entered': {
        'message': 'You need to enter the verification code sent to your e-mail address.',
    },
    'limit_change_failed': {
        'message': LdI18Next.t('modules.error.message.limit_change_failed'),
        'error': true,
        'http_status_code': "-",
    },
};
