import { IFManuals } from '../interfaces';

export interface FileProvider {
  prepareFile(data: IFManuals, formFields: Array<any> | null): { formData: FormData | string, mainUrl: string };
  getFile(formFields: Array<any>): {file: File};

}

export class BrowserFileProvider {


  getFile(formFields: Array<any>) {
    const file = formFields.find(field => field.id === 'file').value;
    return {file};
  }

  prepareFile(data: IFManuals, formFields: Array<any> | null) {
    const file     = formFields?.find(field => field.id === 'file').value;
    const formData = new FormData();

    const mainUrl = data.url;
    formData.append('key', data.fields.key);
    formData.append('x-amz-algorithm', data.fields['x-amz-algorithm']);
    formData.append('x-amz-credential', data.fields['x-amz-credential']);
    formData.append('x-amz-date', data.fields['x-amz-date']);
    formData.append('x-amz-security-token', data.fields['x-amz-security-token']);
    formData.append('policy', data.fields.policy);
    formData.append('x-amz-signature', data.fields['x-amz-signature']);
    formData.append('file', file);
    return { formData, mainUrl };
  }
}

export class DummyFileProvider {

  content = 'This is dummy content for a file';

  setFileContent(content: string) {
    this.content = content;
  }

  prepareFile(data: IFManuals, formFields: Array<any> | null) {
    if (formFields != null) {
      const file = 'test.pdf';
      const formData = "This is dummy content for a file";
      const mainUrl = data.url;
      return { formData, mainUrl };
    }
  }

  getFile(formFields: Array<any>) {
    const blob = new Blob([this.content]);
    const fileName = formFields.find(field => field.id === 'file').value;

    const file = new File([blob], fileName, {
      type: 'application/plain',
    });
    return {file};
  }

}
