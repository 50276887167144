import { css } from "lit";

export const idsColorPalette = css`

  /* These are the colors according to https://swmachines.atlassian.net/wiki/pages/viewpageattachments.action?pageId=3576693&preview=%2F3576693%2F3636639%2FSW_Styleguide_07.pdf&search_id=2f28100d-6c35-473f-b161-7ac0ee7909cb */
  /* Currently only a subset is listed here. These are the ones currently used. */
  :host {

    --sw-ids-dark-blue-001: #031035;
    --sw-ids-dark-blue-002: #243a4f;
    --sw-ids-dark-blue-003: #677583;
    --sw-ids-dark-blue-004: #98a3ad;
    
    --sw-ids-basic-001: #333333;
    --sw-ids-basic-002: #f2f2f2;

  }

  :host {
    --color-primary: var(--sw-ids-basic-001);
    --color-accent: var(--sw-primary-red);

    --background-surface: var(#ffffff);
  }

`