/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { LitElement, html } from 'lit-element';
import { connect } from 'pwa-helpers/connect-mixin';
import { store } from '../../ld-redux/ld-redux-store';
import '@polymer/paper-tooltip/paper-tooltip';

import { isUserSwEmployee, forward } from '../../../js/LdGlobal';
import { LdI18NextMixin } from '../../ld-application/i18n/ld-i18next';

import { LdSidebarStyles } from './ld-sidebar-styles';
import './ld-sidebar-item';

/**
 * Class for displaying a &lt;ld-sidebar&gt; element.
 * @extends LitElement
 */
class LdSidebar extends connect(store)(LdI18NextMixin(LitElement)) {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      activeModule: { type: String, reflect: true },
      pageModules: { type: String, reflect: true },
      _userInformation: { type: Object, reflect: true }
    };
  }

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdSidebarStyles];
  }

  /**
   * render method, used to display the rendered template
   *
   * @returns {String} Rendered literals template
   */
  // --------------------------------------------------------------------------------------
  render() {
    const sidebarItems = [];
    JSON.parse(this.pageModules).forEach(item => {

      if (item.show !== false) {
        if ((item.swOnly && isUserSwEmployee(this._userInformation)) || item.swOnly && item.restrictedTo.includes(this._userInformation.role) || !item.swOnly) {          sidebarItems.push(html`
            <ld-sidebar-item
              id="${JSON.parse(JSON.stringify(item.id))}" 
              item="${JSON.stringify(item)}" 
              @click="${e => this._onButtonClick(e, JSON.stringify(item))}" 
              class="${(this.activeModule === item.id) ? 'active' : ''}">

            </ld-sidebar-item>
            <paper-tooltip for="${JSON.parse(JSON.stringify(item.id))}" position="right">${JSON.parse(JSON.stringify(this.LdTranslate(item.name)))}</paper-tooltip>

          `);
        }
      }
    });

    return html`
      ${sidebarItems}
    `;
  }

  // --------------------------------------------------------------------------------------
  _onButtonClick(evt, item) {
    const _item = JSON.parse(item);
    forward(_item.href);
  }

}

customElements.define('ld-sidebar', LdSidebar);
