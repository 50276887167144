/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { css, html, LitElement, unsafeCSS } from 'lit-element';
import '@polymer/iron-icon/iron-icon';
import '@polymer/paper-tooltip/paper-tooltip';
import { LdI18NextMixin } from '../../ld-application/i18n/ld-i18next';

import './ld-search-result-template-selector';
import { LdSearchStyles } from './styles';
import { _THEME } from '../../../themes/styles';

/**
 * Class for displaying a &lt;ld-sidebar-item&gt; element.
 * @extends LitElement
 */
class LdSearchResults extends LdI18NextMixin(LitElement) {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      opened: { type: Boolean, notify: true, reflectToAttribute: true },
      results: { type: Object, notify: true },
      found: { type: Number },
    };
  }

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [css`
      :host {
        display: none;
        background-color: ${unsafeCSS(_THEME.colors.primary_background_color)};
        box-shadow: 0 13px 30px rgba(0, 0, 0, 0.4), 0 6px 14px rgba(0, 0, 0, 0.3);
        max-height: 450px;
        overflow: auto;
      }

      :host([opened='true']) {
        display: block;
        position: absolute;
        z-index: 100;
      }
    `, LdSearchStyles];
  }

  /**
   * render method, used to display the rendered template
   *
   * @returns {String} Rendered literals template
   */
  // --------------------------------------------------------------------------------------
  render() {
    const output = [];
    if (typeof this.results === 'undefined' || Object.keys(this.results).length === 0) {
      return html`
        <div class='header paper-font-headline'>${this.LdTranslate('modules.search.noResults')}</div>`;
    } else {
      const resultsArray = this._computeResultArray(this.results);

      output.push(
        html`
          <div class='header paper-font-headline'>
            ${resultsArray.length} ${this.LdTranslate('modules.search.results.of').toLowerCase()} ${this.found}
            ${this.LdTranslate('modules.search.results.machines')}
          </div>
        `,
      );
      resultsArray.forEach(child => {
        output.push(
          html`
            <ld-search-result-template-selector .data='${child.value.fields}'></ld-search-result-template-selector>
          `,
        );
      });
      output.push(html`
        <hr>`);
      return html`${output}`;
    }
  }

  /**
   * @param  {} obj
   */
  // --------------------------------------------------------------------------------------
  _computeResultArray(obj) {
    return Object.keys(obj).map(key => ({
      name: key,
      value: obj[key],
    }));
  }
}

customElements.define('ld-search-results', LdSearchResults);
