/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */


'use strict';

import { unsafeCSS, css } from 'lit-element';
import { _THEME } from '../../themes/styles';

export const LdWaitingStyles = css`
  :host {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    resize: both;
    overflow: auto;
    position: fixed;
    top:0;
    left:0;
    z-index: 99998;
    background: #001935;
    background: -moz-linear-gradient(top, #001935 0%, #000111 100%);
    background: -webkit-linear-gradient(top, #001935 0%,#000111 100%);
    background: linear-gradient(to bottom, #001935 0%,#000111 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001935', endColorstr='#000111',GradientType=0 );
  }

  #ld-waiting {
    color: ${unsafeCSS(_THEME.colors.primary_text_color)};
    font-size: 24px;
    font-weight:bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #ld-waiting:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);
    }
    40% {
      color: white;
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);
    }
    60% {
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 rgba(0,0,0,0);
    }
    80%, 100% {
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;
    }
  }
`;

export default LdWaitingStyles;
