/**
 * Copyright 2023 Schwäbische Werkzeugmaschinen GmbH
 */

import '@polymer/paper-tabs/paper-tab';
import '@polymer/paper-tabs/paper-tabs';
import { customElement, html, LitElement, property, TemplateResult } from 'lit-element';
import LdPlatformModulesAssetsStyles                                 from '../modules/assets/styles';

/**
 * LdDialogs module which handles all LdDialogs-management actions
 * @extends LitElement
 */
@customElement('ld-dialogs')
export class LdDialogs extends (LitElement) {

  // --------------------------------------------------------------------------------------
  @property({ type: Boolean, hasChanged: () => true }) opened: boolean        = false;
  @property({ type: Boolean, hasChanged: () => true }) acceptDecline: boolean = false;
  @property({ type: String, hasChanged: () => true }) titleText: string       = '';
  @property({ type: String, hasChanged: () => true }) name: string            = '';
  @property({ type: String, hasChanged: () => true }) dismissText: string     = '';
  @property({ type: String, hasChanged: () => true }) confirmText: string     = '';
  @property({ type: Boolean, hasChanged: () => true }) bigPopup: boolean = false;


  // --------------------------------------------------------------------------------------
  static styles = [LdPlatformModulesAssetsStyles];

  // --------------------------------------------------------------------------------------
  render(): TemplateResult {
    const button = [];
    if (this.dismissText !== '') {
      button.push(html`
        <paper-button @click='${() => this.decline()}' value='cancel' raised class='secondary-action'>
          ${this.dismissText}
        </paper-button>`);
    }

    if (this.confirmText !== '') {
      button.push(html`
        <paper-button @click='${() => this.accept()}' value='default' raised class='primary-action'>
          ${this.confirmText}
        </paper-button>`);
    }

    return html`
      <dialog id='delete-dialog' class='${this.bigPopup ? `biggerDialog` : `` }'>
        <div class='modal-title'>${this.titleText}
          ${this.name}
        </div>
        <slot part='tab'></slot>
        <form method='dialog'>
          <div class='form-actions'>
            <div class='primary-and-secondary-form-actions'>
              ${button}
            </div>
          </div>
        </form>
      </dialog>
    `;
  }

  // --------------------------------------------------------------------------------------
  accept(): void {
    this.dispatchEvent(new CustomEvent('accept-clicked'));
  }

  // --------------------------------------------------------------------------------------
  decline(): void {
    this.dispatchEvent(new CustomEvent('decline-clicked'));
  }

  // --------------------------------------------------------------------------------------
  openDialog(): void {
    const deleteDialog: HTMLDialogElement = this.shadowRoot?.querySelector('#delete-dialog')!;
    if (!deleteDialog) {
      return;
    }
    deleteDialog.showModal();
  }

  // --------------------------------------------------------------------------------------
  closeDialog(): void {
    const deleteDialog: HTMLDialogElement = this.shadowRoot?.querySelector('#delete-dialog')!;
    if (!deleteDialog) {
      return;
    }
    deleteDialog.close();
  }
}
