/**
 * Copyright 2018 Schwäbische Werkzeugmaschinen GmbH
 */

import { LitElement, html } from 'lit-element';
import '@polymer/paper-tabs/paper-tabs';
import '@polymer/paper-tabs/paper-tab';

import { LdTabStyles } from './styles';

/**
 * @extends LitElement
 */
class LdTabs extends LitElement {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      tabs: { type: Object, hasChanged: () => true },
      fallbackSelection: { type: String, hasChanged: () => true },
      routeData: { type: Object, hasChanged: () => true },
      selected: { type: String, hasChanged: () => true },
      innerTab: { type: Boolean, hasChanged: () => true }
    };
  }

  // --------------------------------------------------------------------------------------
  firstUpdated() {
    this.addEventListener('iron-items-changed', e => {
      const _tabElement = this.shadowRoot.querySelector('paper-tabs');
      _tabElement.select(_tabElement.getAttribute('selected'));
    });
  }

  static get styles() {
    return [LdTabStyles];
  }

  // --------------------------------------------------------------------------------------
  render() {
    const allTabs = [];

    this.tabs.forEach(item => {
        allTabs.push(html`
          <paper-tab class="${this.innerTab ? 'innerPaperTab' : ''}" .name='${item.key}' link ?disabled='${item.disabled === true}' ?active='${this.selected === item.key}'>
            <a class="${this.innerTab ? 'innerTab' : ''}" href='${item.link}' class='link'>${item.title}</a>
          </paper-tab>
        `);
    });

    return html`
      <paper-tabs selected='${this.selected}' attr-for-selected='name' noink fallback-selection='${this._getFirstTabItem(this.tabs)}' scrollable>
        ${allTabs}
      </paper-tabs>

    `;
  }

  // --------------------------------------------------------------------------------------
  _getFirstTabItem(tabs) {
    return (this.fallbackSelection != null) ? this.fallbackSelection : tabs[Object.keys(tabs)[0]].link;
  }

}

customElements.define('ld-tabs', LdTabs);
