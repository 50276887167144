/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { unsafeCSS, css } from 'lit-element';
import { _THEME } from '../../../themes/styles';

export const LdSidebarStyles = css`
  .active {
    border-left: 4px ${unsafeCSS(_THEME.colors.primary_color)} solid !important;
    background-color: ${unsafeCSS(_THEME.colors.app_drawer_sidebar_background_color)};
  }
`;

export default LdSidebarStyles;
