/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

// eslint-disable-next-line import/no-cycle
import {
  START_LOADING_USER_INFORMATION,
  FINISH_LOADING_USER_INFORMATION,
  ERROR_LOADING_USER_INFORMATION,
  START_LOADING_USER_SETTINGS,
  FINISH_LOADING_USER_SETTINGS,
  ERROR_LOADING_USER_SETTINGS,
  START_LOADING_SAVED_USER_SETTINGS,
  FINISH_LOADING_SAVED_USER_SETTINGS,
  ERROR_LOADING_SAVED_USER_SETTINGS,
  CHECK_PRIVACY_POLICY_STATUS,
  LOGOUT_STATUS,
} from '../actions/ld-users-actions';

const LdUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_STATE':
      return {};
    case 'DUMMY_STATE':
      return {...state, isDummy: true};
    case START_LOADING_USER_INFORMATION:
      return {
        ...state,
        isUserInfoLoading: true
      };
    case FINISH_LOADING_USER_INFORMATION:
      return {
        ...state,
        info: action.userInfo,
        isUserInfoLoading: false
      };
    case ERROR_LOADING_USER_INFORMATION:
      return {
        ...state,
        result: action.error,
        isUserInfoLoading: false
      };

    // --------------------------------------------------------------------------------------
    case START_LOADING_USER_SETTINGS:
      return {
        ...state,
        isUserSettingsLoading: true
      };
    case FINISH_LOADING_USER_SETTINGS:
      return {
        ...state,
        settings: Object.assign({}, state.userSettings, { [action.setting]: action.userSettings }),
        isUserSettingsLoading: false
      };
    case ERROR_LOADING_USER_SETTINGS:
      return {
        ...state,
        result: action.error,
        isUserSettingsLoading: false
      };

      // --------------------------------------------------------------------------------------
    case START_LOADING_SAVED_USER_SETTINGS:
      return {
        ...state,
        isSavedUserSettingsLoading: true
      };
    case FINISH_LOADING_SAVED_USER_SETTINGS:
      if(action.setting === 'dashboardConfig'){
        return {
          ...state,
          savedSettings: action.setting,
          isSavedUserSettingsLoading: false
        };
      } else {
        return {
          ...state,
          savedSettings: action.savedUserSettings,
          isSavedUserSettingsLoading: false
        };
      }
    case ERROR_LOADING_SAVED_USER_SETTINGS:
      return {
        ...state,
        result: action.error,
        isSavedUserSettingsLoading: false
      };

    // --------------------------------------------------------------------------------------
    case CHECK_PRIVACY_POLICY_STATUS:
      return {
        ...state,
        userHasAcceptedPrivacyPolicyStatus: action.status
      };

    // --------------------------------------------------------------------------------------
    case LOGOUT_STATUS:
      return {
        ...state,
        userLogoutStatus: action.status
      };
    default:
      return state;
  }
};

export default LdUsersReducer;
