/**
 * Copyright 2023 Schwäbische Werkzeugmaschinen GmbH
 */

import { CSSResultArray, html, LitElement, property, PropertyValues, TemplateResult } from 'lit-element';
import { connect }                                                                    from 'pwa-helpers/connect-mixin';
import { IFBreadCrumbConfigObject, IFBreadcrumbObject }                               from '../../../js/interfaces';
import { linkForward }                                                                from '../../../js/LdGlobal';
import {
  clearBreadCrumbConfig,
  getBreadCrumbConfig,
  setBreadCrumbConfig,
}                                                                                     from '../../ld-redux/actions/breadcrumb-actions';
import {
  loadUserSetting,
}                                                                                     from '../../ld-redux/actions/ld-users-actions';
import store
                                                                                      from '../../ld-redux/ld-redux-store';
import { LdI18NextMixin }                                                             from '../i18n/ld-i18next';
import { LdBreadcrumbsStyles }                                                        from './styles';

export class LdBreadCrumb extends connect(store)<any>(LdI18NextMixin(LitElement)) {

  @property({ type: Array, hasChanged: () => true }) breadCrumbConfig: Array<IFBreadCrumbConfigObject> = [];
  @property({ type: Array, hasChanged: () => true }) breadCrumbs: Array<IFBreadcrumbObject>            = [];
  @property({ type: Array, hasChanged: () => true }) crumbNodes: Array<TemplateResult>                 = [];
  @property({ type: Object, hasChanged: () => true }) subModules: any;
  @property({ type: Boolean, hasChanged: () => true }) _breadcrumbVisible: boolean | null              = false;
  @property({ type: Object }) _defaultBreadcrumbAdministration: IFBreadCrumbConfigObject               = {
    assetType: null,
    activeLastCrumb: true,
    assetID: null,
    backButtonLink: null,
  };
  @property({ type: Boolean, hasChanged: () => true }) _breadcrumbVisibleSet: boolean                  = false;

  // --------------------------------------------------------------------------------------
  static get styles(): CSSResultArray {
    return [LdBreadcrumbsStyles];
  }

  // --------------------------------------------------------------------------------------
  constructor() {
    super();
    this._breadcrumbVisible = JSON.parse(localStorage.getItem('breadcrumb')!);
  }

  // --------------------------------------------------------------------------------------
  static setBreadcrumb(breadcrumbConfig: IFBreadCrumbConfigObject): void {
    if (breadcrumbConfig.assetType != null && breadcrumbConfig.assetID != null) {
      store.dispatch(setBreadCrumbConfig(breadcrumbConfig));
      store.dispatch(getBreadCrumbConfig(breadcrumbConfig.assetType, breadcrumbConfig.assetID));
    } else {
      store.dispatch(clearBreadCrumbConfig());
    }
  }

  // --------------------------------------------------------------------------------------
  render(): TemplateResult {
    this._checkBreadcrumbVisible();
    this.setBreadCrumb();

    return html`
      <div id='breadcrumb'>
        <ul class='breadcrumb'>
          ${this.crumbNodes}
        </ul>
      </div>
    `;

  }

  // --------------------------------------------------------------------------------------
  stateChanged(state: any) {
    const { breadCrumb, breadCrumbConfig } = state.BreadCrumbReducer;
    const { settings }                     = state.LdUsersReducer;
    if (settings?.breadcrumb != null && this._breadcrumbVisible == null) {
      this._breadcrumbVisible    = settings.breadcrumb;
      this._breadcrumbVisibleSet = true;
      this.render();
    }

    this.breadCrumbConfig = breadCrumbConfig;
    this.breadCrumbs      = breadCrumb;

  }

  // --------------------------------------------------------------------------------------
  public setBreadCrumb(): void {
    this.crumbNodes = [];
    const breadCrumbConfig = { ...this._defaultBreadcrumbAdministration, ...this.breadCrumbConfig };
    if (this.breadCrumbConfig != null && this.breadCrumbConfig.length === 0) {
        return;
    }
    if (breadCrumbConfig.backButtonLink != null) {
      this.crumbNodes.push(html`
        <ld-assets-backbutton parent='${breadCrumbConfig.backButtonLink}'></ld-assets-backbutton>`);
    }

    if (this._breadcrumbVisible == null || this._breadcrumbVisible) {

      // if Standard breadcrumb is desired
      if (this.breadCrumbs != null && this.breadCrumbs.length === 0) {
        return;
      }
      const length = this.breadCrumbs?.length;

      this.breadCrumbs?.forEach((item: any, index: number) => {

        if (index !== this.breadCrumbs?.length - 1) {
          this.crumbNodes.push(html`
            <li @click='${() => linkForward(item.uri)}'><a>${item.name}</a></li>`);
        } else {
          this.crumbNodes.push(html`
            <li>${item.name}</li>`);
        }
      });
    }

  }

  _checkBreadcrumbVisible(): void {
    if (localStorage.getItem('breadcrumb') == null) {
      if (!this._breadcrumbVisibleSet) {
        store.dispatch(loadUserSetting(`breadcrumb`));
      }
    } else {
      this._breadcrumbVisible    = JSON.parse(localStorage.getItem('breadcrumb')!);
    }
    this._breadcrumbVisibleSet = true;

  }
}

customElements.define('ld-breadcrumb', LdBreadCrumb as unknown as CustomElementConstructor);
