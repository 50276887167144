/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { LitElement, html } from 'lit-element';
import LdLoadingSpinnerStyles from './styles';
import '@polymer/paper-spinner/paper-spinner';
import '@polymer/paper-dialog/paper-dialog';


class LdLoadingSpinner extends LitElement {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      parent: { type: String, hasChanged: () => true }

    };
  }
  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdLoadingSpinnerStyles];
  }

  /**
   * render method, used to display the rendered template
   *
   * @returns {String} Rendered literals template
   */
  // --------------------------------------------------------------------------------------
  render() {
    return html`
      <paper-dialog class='ld-spinner-pop-out' modal>
        <paper-spinner class="ld-loading-spinner" active></paper-spinner>
      </paper-dialog>
    `;
  }

  // --------------------------------------------------------------------------------------
  constructor() {
    super();
    this._openSpinner = this.openLoadingSpinner.bind(this);
    this._closeSpinner = this.closeLoadingSpinner.bind(this);
    this.parent = 'ld-application';
  }

  /**
   * create an eventListener
   */
  // --------------------------------------------------------------------------------------
  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('LdOpenLoadingSpinner', this._openSpinner);
    window.addEventListener('LdCloseLoadingSpinner', this._closeSpinner);
  }

  /**
   * to prevent memory leaks
   */
  // --------------------------------------------------------------------------------------
  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('LdOpenLoadingSpinner', this._openSpinner);
    window.removeEventListener('LdCloseLoadingSpinner', this._closeSpinner);
  }

  /**
   * open the loading spinner
   */
  // --------------------------------------------------------------------------------------
  openLoadingSpinner() {
    const parent =  window.document.querySelector(`${this.parent}`);
    const _spinnerElement = parent.shadowRoot.querySelector('ld-spinner') == null ? parent : parent.shadowRoot.querySelector('ld-spinner');
    _spinnerElement.shadowRoot.querySelector('.ld-spinner-pop-out').open();
  }

  /**
   * close the loading spinner
   */
  // --------------------------------------------------------------------------------------
  closeLoadingSpinner() {
    const parent =  window.document.querySelector(`${this.parent}`);
    const _spinnerElement = parent.shadowRoot.querySelector('ld-spinner') == null ? parent : parent.shadowRoot.querySelector('ld-spinner');
    _spinnerElement.shadowRoot.querySelector('.ld-spinner-pop-out').close();
  }

}

customElements.define('ld-spinner', LdLoadingSpinner);
