/**
 * Copyright 2023 Schwäbische Werkzeugmaschinen GmbH
 */
import { TemplateResult } from 'lit-element';

interface IFBreadcrumbObject {
  parent: string,
  label: string,
  uri: string
}

interface IFBreadCrumbReducer {
  breadCrumbConfig: IFBreadCrumbConfigObject;
}

interface IFManuals {
  url: string,
  fields: any,

}

interface IFMulitValue {
  chartId: number,
  multiCurrentValueList: [number][number]
}

interface IFBreadCrumbConfigObject {
  customBreadcrumbElements?: Array<IFBreadcrumbObject> | null,
  assetType?: string |null,
  activeLastCrumb?: boolean,
  assetID?: string | null,
  backButtonLink?: string | null,
}


export const VisibilityType = {
  External: 'EXTERNAL',
  Internal: 'INTERNAL',
  All: 'ALL',
};

interface IFNcStateFileData {
  creationDate: number,
  event_type: string,
  trigger: string,
  machine_id: string,
  timestamp: number,
}

interface IFMachineTypes {
  name: string
}

interface IFMachinesList {
  cell_position: string,
  controller:    string,
  dates: {start_of_production: number, shipping: number, startup: number, swcp_portal:number, swcp_support: number, swmp_api: number, swsp_line_mon: number, swsp_trace:number}
  deleted: boolean,
  machine_identifier_customer: string,
  machine_type: string,
  name: string
  parent:string
  uri:string
  version_information:string
  version_information_box:string
}

interface IFAlarms {
  alarm_number: string,
  categoryText: string,
  details: string,
  machineTimeFormatted?: string,
  leftTimeFormatted?: string,
  durationFormatted?: string,
  machine_time?: string,
  duration?: string,
  left_time?: string,
  category?: number,
  trend?: TemplateResult,
  countBeforehandTimeRange?:number,
  countSelectedTimeRange?:number,
  count?: number
}

interface IFGridColumns {
  selection?: boolean;
  assetId?: string,
  column_align?: string,
  column_width?: string,
  disable_title?: boolean,
  event?: string,
  secondEvent?: string,
  filter?: boolean,
  hidden?: boolean,
  icon?: IFIcon,
  input?: IFInput,
  id?: string,
  initialSort?: string,
  trafficLight?: IFTrafficLight,
  status?: IFTrafficLight,
  link?: IFLink,
  sort?: boolean,
  sortPath?: string,
  filterPath?: string,
  src?: string,
  style?: string,
  subKey?: string,
  title?: string,
  edit?: boolean,
  visible_for_user_group?: Array<string> | null,
  details?: boolean,
  checkbox_label?: string,
  groupStart?: boolean,
  groupEnd?: boolean,
  groupName?: string,
  contextMenuOptions?: IFcontextMenuOptions,
  tooltip?: string,
  firstInArrayStyle?: string,
  iot?:IFLIot,
  renderOptions?: { clampLinesTo: number; }
}

interface IFcontextMenuOptions {
  link: Array<IFLink>,
  inputOptions: Array<string>
}

interface IFIcon {
  style?: string,
  src?: string,
  srcLink?: string,
  title?: string,
  secondSrc?: string,
  secondTitle?: string,
  text?: string,
  visible?: string
}

interface IFInput {
  min?: number,
  max?: number,
  value?: number,
  hasContorl?: boolean,
  field: string
  id?: string
}

interface IFTrafficLight {
  color?: string,
  text?: string,
  icon?: boolean
}

interface IFLink {
  field?: string,
  prefix?: string,
  suffix?: string
}

interface IFLIot {
  color?: string,
  onlineTime?: string,
}

interface IFMachines {
  machine_identifier_customer: string,
  machine_type: string,
  dates: {
  swcp_portal?: number | null | string,
    warranty_start?: number,
    swmp_api?: number,
    shipping?: number,
    swcp_support?: number,
    swsp_trace?: number,
    startup?: number,
    start_of_production?: number,
    warranty_end?: number,
    swsp_line_mon?: number
},
  references: {
  s3: string,
    ssm: string,
    greengrass: string,
    iot: string
},
  version_information: {
  nc_version: string,
    plc_version: string,
    plc_user_program_version: string,
    plc_toolbox_version: string,
    operating_system: string,
    hmi_version: string,
    compile_cycles: [
    {
      name: string,
      version: string
    },
    {
      name: string,
      version: string
    }
  ]
},
  uri: string,
  parent: string,
  cell_position: string,
  name: string,
  controller: string,
  id: string,
  sendingLabel?: string,
  sendingColor?: string,
  sendingTime?: string,
}

interface IFUser {
  id: string,
  company: string,
  locale: string,
  given_name: string,
  phone_number: string,
  family_name: string,
  role: string,
  email: string,
}


export {
  IFGridColumns,
  IFcontextMenuOptions,
  IFIcon,
  IFInput,
  IFLink,
  IFLIot,
  IFTrafficLight,
  IFBreadcrumbObject,
  IFBreadCrumbReducer,
  IFBreadCrumbConfigObject,
  IFNcStateFileData,
  IFManuals,
  IFAlarms,
  IFMulitValue,
  IFMachineTypes,
  IFMachinesList,
  IFMachines,
  IFUser,
};
