/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { LitElement, html } from 'lit-element';

import '@polymer/iron-icon/iron-icon';
import '@polymer/paper-tooltip/paper-tooltip';
import '@polymer/paper-progress/paper-progress';

import { LdSearchStyles } from './styles';
import { LdI18NextMixin } from '../../ld-application/i18n/ld-i18next';
import debounce from './debounce';

import './ld-search-results';
import searchService from '../../../js/services/searchService';
import { forward } from '../../../js/LdGlobal';

/**
 * Class for displaying a &lt;ld-sidebar-item&gt; element.
 * @extends LitElement
 */
class LdSearchInput extends LdI18NextMixin(LitElement) {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      searchInput: { type: String, hasChanged: () => true },
      minChars: { type: Number, notify: true, },
      resultsOpened: { type: Boolean },
      loading: { type: Boolean, notify: true },
      results: { type: Object },
      resultsFound: { type: Number },
      _searchFieldTimeout: { type: Number },
      _pendingRequest: { type: Promise },
      returnDataMode: { type: Boolean, notify: true },
    };
  }

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdSearchStyles];
  }

  // --------------------------------------------------------------------------------------
  firstUpdated() {
    this.addEventListener('blur', this._blurListener);
    this.addEventListener('focus', this._focusListener);
    this.addEventListener('keydown', this._resultKeydownListener);
  }

  // --------------------------------------------------------------------------------------
  constructor() {
    super();

    this.searchInput = '';
    this.minChars = 3;
    this.resultsOpened = false;
    this.loading = false;
    this._searchFieldTimeout = 1000;
    this.results = {};
    this._debouncedExecuteSearch = debounce(this._executeSearch, 1000, {
      leading: true,
      trailing: true,
    });
    this.returnDataMode = false;
  }

  /**
   * render method, used to display the rendered template
   *
   * @returns {String} Rendered literals template
   */
  // --------------------------------------------------------------------------------------
  render() {
    const inputReference = this.shadowRoot.querySelector('#search-input')
    const resultWidth = inputReference && inputReference.clientWidth || 150;

    return html`
    <iron-icon id='search-icon' icon='icons:search'></iron-icon>
    <form>
      <input
        type='search'
        value='${this.searchInput}' 
        id='search-input'
        autocomplete='off'
        @input='${this._searchInputChanged}'
        placeholder='${this.LdTranslate('modules.search.callToAction')}'
      >
    </form>
    <paper-progress indeterminate ?disabled='${!this.loading}'></paper-progress>
    <ld-search-results
      id='results'
      opened='${this.resultsOpened}' 
      .results='${this.results}' 
      found='${this.resultsFound}'
      @result-selected='${this._resultSelected}'
      style="width: ${resultWidth}px"
    ></ld-search-results>
    `;

  }

  _focusListener() {
    if (Object.keys(this.results).length > 0) {
      this.resultsOpened = true;
    }
  }

  _blurListener() {
    this.resultsOpened = false;
  }

  _resultKeydownListener(event) {
    if (event.keyCode === 27) { // ESC key
      this.searchInput = '';
      this.resultsOpened = false;;
      this.results = {};
    } else if (event.keyCode === 13) { // Enter key
      event.preventDefault();
      this.resultsOpened = false;
      this.searchInput = '';
    } else if (event.keyCode === 40) { // arrow-key down
      const paperItems = this._getResultEntries();
      const selectedItemIndex = paperItems.findIndex(paperItem => paperItem.getAttribute('focused') === '');

      if(selectedItemIndex === -1) {
        paperItems[0].focus();
      } else if(selectedItemIndex === paperItems.length -1) {

      } else {
        paperItems[selectedItemIndex + 1].focus();
      }
    } else if (event.keyCode === 38) { // arrow-key up
      const paperItems = this._getResultEntries();
      const selectedItemIndex = paperItems.findIndex(paperItem => paperItem.getAttribute('focused') === '');

      if(selectedItemIndex === -1) {
        paperItems[0].focus();
      } else if(selectedItemIndex === 0) {

      } else {
        paperItems[selectedItemIndex - 1].focus();
      }
    }
  }

  _searchInputChanged() {
    this.searchInput = this.shadowRoot.getElementById('search-input').value;
    if (this.searchInput.trim().length >= this.minChars) {
      this._debouncedExecuteSearch(this.searchInput);
    }
    if (this.searchInput === '') {
      this.resultsOpened = false;
      this.results = {};
    }
  }

  _executeSearch(searchInput) {
    if(this._pendingRequest) {
      this._pendingRequest.abort();
    }
    searchInput = searchInput.replaceAll(' ', '*');
    this._pendingRequest = searchService.searchAll(searchInput);
    this.loading = true;
    this._pendingRequest
      .then((response) => {
        this.loading = false;
        this._pendingRequest = null;
        this.results = response.hits.hit;
        this.resultsFound = response.hits.found;
        this.resultsOpened = true;
      })
      .catch((error) => {
        this.loading = false;
      });
  }

  _getResultEntries() {
    const searchResult = this.shadowRoot.querySelector('ld-search-results');
    const searchResultTemplates = searchResult.shadowRoot.querySelectorAll('ld-search-result-template-selector');
    const paperItems = [].map.call(searchResultTemplates, (searchResultTemplate) => {
      const searchResultSpecific = searchResultTemplate.shadowRoot.children[0];
      const searchResultGeneric = searchResultSpecific.shadowRoot.children[0];
      const iconItem = searchResultGeneric.shadowRoot.querySelector('paper-icon-item');
      return iconItem;
    });
    return paperItems;
  }

  _resultSelected(event) {
    event.stopPropagation();
    const { detail } = event;
    this.searchInput = '';
    this.resultsOpened = false;
    if(this.returnDataMode) {
      detail.preventNavigation();
      this.dispatchEvent(new CustomEvent('result-selected', {
        detail,
        composed: true,
      }));
    }
  }

}

customElements.define('ld-search-input', LdSearchInput);
