/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { CONFIG } from '../LdGlobal';
import LdFetch from '../LdFetch';

const { base_url, endpoints } = CONFIG.backend.services.save_user_settings;
const url = `${base_url}${endpoints.settings}`;

function saveSetting(key, settingsObj) {
  return new Promise((resolve, reject) => {
    LdFetch.post(`${url}/${key}`, settingsObj, resolve, reject);
  });
}

function loadSetting(key) {
  return new Promise((resolve, reject) => {
    LdFetch.get(`${url}/${key}`, resolve, reject);
  });
}

export default {
  saveSetting,
  loadSetting,
};
