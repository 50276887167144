/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { unsafeCSS, css } from 'lit-element';
import { _THEME } from '../../../themes/styles';

export const LdSearchStyles = css`
  #search-input {
    box-sizing: border-box;
    width: 100%;
    padding-left: 50px;
    padding-right: 16px;
    line-height: 50px;
    height: 50px;
    border: none;
    border-radius: 4px;
    margin: 0;
    margin-bottom: 1px;
    background-color: ${unsafeCSS(_THEME.colors.search_field_background_color)};
    font-family: ${unsafeCSS(_THEME.fonts.main_font)};
    font-size: 16px;
    color: ${unsafeCSS(_THEME.colors.search_field_text_color)};
    -webkit-appearance: none; /* Safari support */
  }

  #search-input:focus {
    background-color: ${unsafeCSS(_THEME.colors.primary_background_color)};
  }

  #search-input::-webkit-input-placeholder {
    font-family: ${unsafeCSS(_THEME.fonts.main_font)};
    font-size: 16px;
    color: ${unsafeCSS(_THEME.colors.search_field_text_color)};
  }

  #search-input::-moz-placeholder {
    font-family: ${unsafeCSS(_THEME.fonts.main_font)};
    font-size: 16px;
    color: ${unsafeCSS(_THEME.colors.search_field_text_color)};
  }

  #search-input:-ms-input-placeholder {
    font-family: ${unsafeCSS(_THEME.fonts.main_font)};
    font-size: 16px;
    color: ${unsafeCSS(_THEME.colors.search_field_text_color)};
  }

  #search-icon {
    position: absolute;
    width: 32px;
    height: 32px;
    padding: 10px;
    color: ${unsafeCSS(_THEME.colors.icon_button_color)};
  }

  #clear-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    position: absolute;
    color: ${unsafeCSS(_THEME.colors.icon_button_color)};
    top: 15px;
    left: 30%;
    cursor: pointer;
  }

  paper-progress {
    width: 100%;
    margin-top: -4px;
    --paper-progress-active-color: ${unsafeCSS(_THEME.colors.primary_highlight_color)};
    --paper-progress-container-color: transparent;
    --paper-progress-indeterminate-cycle-duration: 2s;
    --paper-progress-transition-transition-delay: 250ms;
    border: none;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  div.header {
    color: ${unsafeCSS(_THEME.colors.search_field_text_color)};
    padding: 18px 16px 8px 22px;
    font-size: 16px;
  }

  div.header:last-of-type {
    margin-bottom: 5px;
  }

  hr {
    border-style: solid;
    border-width: .5px;
    border-color: ${unsafeCSS(_THEME.colors.default_borders_color)};
    margin-block-start: 1em;
    margin-block-end: 0.3em;
  }

  hr:last-of-type {
    display: none;
  }
  
  iron-icon {
    width: 36px;
    height: 36px;
    padding-left: 6px;
    padding-right: 15px;
  }

  .paper-item-link {
    color: inherit;
    text-decoration: none;
  }

  paper-icon-item:hover,
  paper-icon-item:focus {
    background-color: ${unsafeCSS(_THEME.colors.search_field_item_background_color)};
    padding-left: 11px;
    border-left: 5px ${unsafeCSS(_THEME.colors.primary_highlight_color)} solid
  }

  @media only screen and (min-width: 600px ) {
    #search-input:focus {
      background-color: ${unsafeCSS(_THEME.colors.primary_background_color)};
      position: absolute;
      width: 50%;
    }
  }
  

  @media only screen and (min-width: 1260px ) {

    #search-input:focus {
      background-color: ${unsafeCSS(_THEME.colors.primary_background_color)};
      position: inherit;
      width: 100%;

    }
  }
`;

export default LdSearchStyles;
